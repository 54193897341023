import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import Loader from "../../components/loader/loader";
import { BASE_URL } from "../../config/endPoint";
import { httpGet, httpPost } from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddUpdateStone = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateData, setUpdateData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Stone Name is required"),
    }),
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (id) {
      getStoneById();
    }
  }, []);

  useEffect(() => {
    if (updateData) {
      formik.setValues({
        name: updateData?.name || "",
      });
    }
  }, [updateData]);

  const getStoneById = async () => {
    try {
      const response = await httpGet(`${BASE_URL}/stone/${id}`);
      let responseData = response?.data?.response;
      setUpdateData(responseData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdate = async (values) => {
    try {
      setIsLoading(true);

      const updatedData = {
        _id: id,
        name: values.name,
      };

      const response = await httpPost(`${BASE_URL}/stone/update`, updatedData);

      if (response.data.status) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/stones");
        setIsLoading(false);
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error updating stone:", error);
      setIsLoading(false);
    }
  };

  function handleSubmit(values) {
    if (id) {
      handleUpdate(values);
    } else {
      setIsLoading(true);

      let obj = values;
      httpPost(`${BASE_URL}/stone/create`, obj)
        .then((result) => {
          console.log("result", result.data);
          if (result.data.status) {
            toast.success(result.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            navigate("/stones");
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      {isLoading && <Loader />}
      <DashboardLayout>
        <div className="titles">
          <Container style={{ width: "900px" }}>
            <div className="admin-main">
              <div className="titles_backBtn">
                <Link to="/addStoneType">
                  <AiOutlineLeft fa-lg />
                  {id ? " Update Stone Type" : " Add Stone Type"}
                </Link>
              </div>
              <hr />
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required-FIELD">Add Stone Name</Form.Label>
                  <Form.Control
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="name"
                    type="text"
                    placeholder={"Enter Stone Name"}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="error">{formik.errors.name}</div>
                  ) : null}
                </Form.Group>
                <Button className="submit_btn" type="submit">
                  {id ? "Update" : "Save"}
                </Button>
              </Form>
            </div>
          </Container>
        </div>
      </DashboardLayout>
    </>
  );
};

export default AddUpdateStone;
