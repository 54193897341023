export const diamondShapes = [
    {
        name: "Marquise",
        value: "Marquise"
    },
    {
        name: "Oval",
        value: "Oval"
    },
    {
        name: "Round",
        value: "Round"
    },
    {
        name: "Pear",
        value: "Pear"
    },
    {
        name: "Emerald",
        value: "Emerald"
    },
    {
        name: "Princess",
        value: "Princess"
    },
    {
        name: "Cushion",
        value: "Cushion"
    },
    {
        name: "Heart",
        value: "Heart"
    },
    {
        name: "Asher",
        value: "Asher"
    },
    {
        name: "Other",
        value: "Other"
    }
];

export const diamondColor = [
    {
        name: "Black",
        value: "Black"
    },
    {
        name: "White",
        value: "White"
    },
    {
        name: "Brown",
        value: "Brown"
    },
    {
        name: "Other",
        value: "Other"
    },
];

export const sizeRanges = [
    "21mm - 23mm",
    "24mm - 26mm",
    "27mm - 29mm",
    "30mm - 32mm",
    "33mm - 35mm",
    "36mm - 38mm",
    "39mm - 41mm",
    "42mm - 44mm",
    "45mm - 46mm"
];

export const caseMaterials = [
    "Stainless Steel",
    "Bronze",
    "Yellow Gold",
    "Rose Gold",
    "Mother of Pearl",
    "Titanium",
    "Ceramic",
    "Ceramos",
    "Metal",
    "Other"
  ];


  export const watchWaterResistance = [
    "10m - 200m",
    "200m - 400m",
    "400m - 600m",
    "600m - 800m",
    "900m - 1200m",
    "1200m - 1400m",
    "1400m - 1600m",
    "1600m - 1800m",
    "1800m - 2000m"
  ];

  export const watchMovements = [
    "Manual Winding",
    "Automatic",
    "Quartz",
    "Eco Drive"
  ];

  export const FRAME_STYLE = [
    {
        name: 'Aviator',
        value: 'Aviator'
    },
    {
        name: 'Cat Eye',
        value: 'Cat Eye'
    },
    {
        name: 'Classic',
        value: 'Classic'
    },
    {
        name: 'Fashion',
        value: 'Fashion'
    },
    {
        name: 'Luxury',
        value: 'Luxury'
    },
    {
        name: 'Rectangular',
        value: 'Rectangular'
    },
    {
        name: 'Rimless',
        value: 'Rimless'
    },
    {
        name: 'Shield',
        value: 'Shield'
    },
    {
        name: 'Wrap',
        value: 'Wrap'
    },
    {
        name: 'Other',
        value: 'Other'
    },
]

export const FRAME_COLOR = [
    { label: 'Black', value: 'Black' },
    { label: 'Blue', value: 'Blue' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Gold', value: 'Gold' },
    { label: 'Grey', value: 'Grey' },
    { label: 'Clear', value: 'Clear' },
    { label: 'Green', value: 'Green' },
    { label: 'Yellow', value: 'Yellow' },
    { label: 'Gray', value: 'Gray' },
    { label: 'Metallic', value: 'Metallic' },
    { label: 'Pink', value: 'Pink' },
    { label: 'Red', value: 'Red' },
    { label: 'Purple', value: 'Purple' },
    { label: 'Silver', value: 'Silver' },
    { label: 'Orange', value: 'Orange' },
    { label: 'White', value: 'White' },
    { label: 'Tortoise', value: 'Tortoise' },
    { label: 'Black Horn/Blue', value: 'Black Horn/Blue' },
    { label: 'Transparent', value: 'Transparent' },
    { label: 'Blonde Havana', value: 'Blonde Havana' },
    { label: 'Dark Havana', value: 'Dark Havana' },
    { label: 'Deep Gold', value: 'Deep Gold' },
    { label: 'Gradient Havana', value: 'Gradient Havana' },
    { label: 'Havana', value: 'Havana' },
    { label: 'Mastic', value: 'Mastic' },
    { label: 'Matte Black', value: 'Matte Black' },
    { label: 'Rose Gold', value: 'Rose Gold' },
    { label: 'Shiny Black', value: 'Shiny Black' },
    { label: 'Shiny Gunmetal', value: 'Shiny Gunmetal' },
    { label: 'Shiny Light Blue', value: 'Shiny Light Blue' },
    { label: 'Shiny Light Brown', value: 'Shiny Light Brown' },
    { label: 'Other', value: 'Other' }
];


export const LENS_COLOR = [
    { name: 'Neutral Grey', value: 'Neutral Grey' },
    { name: 'Blue Grey', value: 'Neutral Grey' },
    { name: 'Copper Grey', value: 'Neutral Grey' },
    { name: 'Copper Silver', value: 'Copper Silver' },
    { name: 'Gray', value: 'Gray' },
    { name: 'Gray Silver', value: 'Gray Silver' },
    { name: 'Green', value: 'Green' },
    { name: 'Pink Grey', value: 'Pink Grey' },
    { name: 'Sunrise Silver', value: 'Sunrise Silver' },
    { name: 'Other', value: 'Other' },
]

export const FRAME_MATERIAL = [
    { name: "Acetate", value: "Acetate" },
    // { name: "Innovative", value: "Innovative" },
    { name: "Metal", value: "Metal" },
    // { name: "Titaniums", value: "Titaniums" },
    // { name: "Nylon&Propionate", value: "Nylon&Propionate" },
    { name: "Other", value: "Other" }
];

export const frameColors = [
    { name: 'Black', value: 'Black' },
    { name: 'Blue', value: 'Blue' },
    { name: 'Brown', value: 'Brown' },
    { name: 'Gold', value: 'Gold' },
    { name: 'Grey', value: 'Grey' },
    { name: 'Clear', value: 'Clear' },
    { name: 'Green', value: 'Green' },
    { name: 'Yellow', value: 'Yellow' },
    { name: 'Gray', value: 'Gray' },
    { name: 'Metallic', value: 'Metallic' },
    { name: 'Pink', value: 'Pink' },
    { name: 'Red', value: 'Red' },
    { name: 'Purple', value: 'Purple' },
    { name: 'Silver', value: 'Silver' },
    { name: 'Orange', value: 'Orange' },
    { name: 'White', value: 'White' },
    { name: 'Tortoise', value: 'Tortoise' },
    { name: 'Black Horn/Blue', value: 'Black Horn/Blue' },
    { name: 'Transparent', value: 'Transparent' },
    { name: 'Blonde Havana', value: 'Blonde Havana' },
    { name: 'Dark Havana', value: 'Dark Havana' },
    { name: 'Deep Gold', value: 'Deep Gold' },
    { name: 'Gradient Havana', value: 'Gradient Havana' },
    { name: 'Havana', value: 'Havana' },
    { name: 'Mastic', value: 'Mastic' },
    { name: 'Matte Black', value: 'Matte Black' },
    { name: 'Rose Gold', value: 'Rose Gold' },
    { name: 'Shiny Black', value: 'Shiny Black' },
    { name: 'Shiny Gunmetal', value: 'Shiny Gunmetal' },
    { name: 'Shiny Light Blue', value: 'Shiny Light Blue' },
    { name: 'Shiny Light Brown', value: 'Shiny Light Brown' },
    { name: 'Other', value: 'Other' }
  ];

export const LENS_MATERIAL = [
    { name: "Lightwave Glass", value: "Lightwave Glass" },
    { name: "Polycarbonate", value: "Polycarbonate" },
    { name: "Other", value: "Other" }
];

export const FRAME_FIT = [
    { name: "Small", value: "Small" },
    { name: "Medium", value: "Medium" },
    { name: "Medium - Large", value: "Medium - Large" },
    { name: "Large", value: "Large" },
    { name: "Extra Large", value: "Extra Large" },
    { name: "Other", value: "Other" }
];
