import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Formlogin from "./components/auth/Login";
import Protected from "./components/Protected/Protected";
import ProfilePwd from "./components/Proffile/ProfilePwd";
import Dashboard from "./components/assets/Dashboard";
import ChangePassword from "./pages/changePassword";
import Category from "./pages/category/category";
import EditCategory from "./pages/category/EditCategory";
import AddCategory from "./pages/category/AddCategory";
import Brands from './pages/brands/Brands';
import AddBrands from "./pages/brands/AddBrand";
import Products from "./pages/products/Products";
import AddUpdateProduct from "./pages/products/AddUpdateProduct";
import UserContactDetails from "./pages/userContactDetails/userContactDetails";
import JeweleryType from "./pages/jeweleryType/JeweleryType";
import AddUpdateJeweleryType from "./pages/jeweleryType/AddUpdateJeweleryType";
import MetalType from "./pages/metalType/MetalType";
import AddUpdateMetalType from "./pages/metalType/AddUpdateMetalType";
import Loader from "./components/loader/loader";
import AddUpdateStone from "./pages/stones/AddUpdateStone";
import StoneType from "./pages/stones/StoneType";
import ProductInquiry from "./pages/product-inquiry/ProductInquiry";
import HomePageStory from "./pages/homePageStory/HomePageStory";
import AddStory from "./pages/homePageStory/addStory";
import HomePageBanner from "./pages/homePageBanner/homePageBanner";
import AddHomeBanner from "./pages/homePageBanner/addHomeBanner";
import OffersList from "./pages/offers/offersList";
import AddUpdateOffer from "./pages/offers/addUpdateOffer";
import GrabbedOffers from "./pages/grabbed-offers/GrabbedOffers";
import DiamondTable from "./pages/diamond";
import LensColorTable from "./pages/lensColor";
import MaterialTable from "./pages/material/material";


function App() {
  return (
    
    <Router>
      <Routes>
        
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/category" element={<Protected Cmp={Category} />} />
        <Route path="/addCategory" element={<Protected Cmp={AddCategory} />} />
        <Route path="/editCategory/:id" element={<Protected Cmp={EditCategory} />} />
        <Route path='/brands' element={<Protected Cmp={Brands} />} />
        <Route path='/addBrand' element={<Protected Cmp={AddBrands} />} />
        <Route path='/editBrand/:id' element={<Protected Cmp={AddBrands} />} />
        <Route path="/profile" element={<Protected Cmp={ProfilePwd} />} />
        <Route path="/products" element={<Protected Cmp={Products} />} />
        <Route path="/add-product" element={<Protected Cmp={AddUpdateProduct} />} />
        <Route path="/edit-product/:id" element={<Protected Cmp={AddUpdateProduct} />} />
        <Route path="/userContactList" element={<Protected Cmp={UserContactDetails} />} />
        <Route path='/jeweleryType' element={<Protected Cmp={JeweleryType} />} />
        <Route path="/addJeweleryType" element={<Protected Cmp={AddUpdateJeweleryType} />} />
        <Route path="/editJeweleryType/:id" element={<Protected Cmp={AddUpdateJeweleryType} />} />
        <Route path='/metalType' element={<Protected Cmp={MetalType} />} />
        <Route path="/addMetalType" element={<Protected Cmp={AddUpdateMetalType} />} />
        <Route path="/editMetalType/:id" element={<Protected Cmp={AddUpdateMetalType} />} />
        <Route path='/stones' element={<Protected Cmp={StoneType} />} />
        <Route path="/addStoneType" element={<Protected Cmp={AddUpdateStone} />} />
        <Route path="/product-inquiry" element={<Protected Cmp={ProductInquiry} />} />
        <Route path="/editStoneType/:id" element={<Protected Cmp={AddUpdateStone} />} />
        <Route path="/homePageStory" element={<Protected Cmp={HomePageStory} />} />
        <Route path="/updateStory/:id" element={<Protected Cmp={AddStory} />} />
        <Route path="/homePageBanner" element={<Protected Cmp={HomePageBanner} />} />
        <Route path="/updateBanner/:id" element={<Protected Cmp={AddHomeBanner} />} />
        <Route path="/offers" element={<Protected Cmp={OffersList} />} />
        <Route path="/add-offer" element={<Protected Cmp={AddUpdateOffer} />} />
        <Route path="/edit-offer/:id" element={<Protected Cmp={AddUpdateOffer} />} />
        <Route path="/grabbed-offers" element={<Protected Cmp={GrabbedOffers} />} />
        <Route path="/diamonds" element={<Protected Cmp={DiamondTable} />} />
        <Route path="/color" element={<Protected Cmp={LensColorTable} />} />
        <Route path="/material" element={<Protected Cmp={MaterialTable} />} />
        <Route path="/login" element={<Formlogin />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
      <ToastContainer position="top-right" />
    </Router>
  );
}

export default App;
