import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import { BASE_URL } from "../../config/endPoint";
import { httpDelete, httpGet } from "../../utils/api";

function Products() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setId(id);
    setShow(true);
  };

  const getProducts = async () => {
    await httpGet(`${BASE_URL}/product/list`)
      .then((res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          setData(res.data.response);
        }
      })
      .catch((error) => {
        setIsLoading(false);

        console.error(error);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  const columns = [
    {
      name: (
        <h6>
          <b>Product Name</b>
        </h6>
      ),
      selector: (row) => row?.productName,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Product Description</b>
        </h6>
      ),
      selector: (row) => row?.description && row?.description.slice(0, 100),
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Product Price</b>
        </h6>
      ),
      selector: (row) => row?.price,
      sortable: true,
    },

    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            onClick={() => navigate(`/edit-product/${row?._id} `)}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            {" "}
            <i className="fa-solid fa-pen fa-lg" style={{ color: "blue" }}></i>
          </button>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleShow(row?._id)}
          >
            {" "}
            <i className="fas fa-trash" style={{ color: "#f00" }}></i>
          </button>
        </div>
      ),
    },
  ];

  const deleteProduct = async () => {
    setIsLoading(true);
    await httpDelete(`${BASE_URL}/product/${id}`)
      .then((res) => {
        if (res?.data?.status) {
          setId("");
          getProducts();
          setIsLoading(false);
          toast.success(res?.data?.message);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filteredItems = data.filter(
    (item) =>
      item.productName &&
      item.productName.toLowerCase().includes(search.toLowerCase())
  );

  const actions = (
    <Button
      className="add-btn"
      type="button"
      onClick={() => navigate("/add-product")}
    >
      Add Product
    </Button>
  );

  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          title="Products"
          columns={columns}
          data={filteredItems}
          pagination
          fixedHeader
          actions={actions}
          // fixedHeaderScrollHeight="500px"
          progressPending={isLoading}
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteProduct}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default Products;
