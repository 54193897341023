import React from 'react';
import '../loader/loader.css'

function Loader() {
  return (
    <>
      <div className="loader-outer">
        <span className="loader"></span>
      </div>
    </>
  )
}

export default Loader;
