import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import Form from "react-bootstrap/Form";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import DashboardLayout from "../../components/layouts/dashboardLayout";

const AddCategory = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  function handleSubmit(e) {
    e.preventDefault();
  }

  async function save() {
    let obj = {
      name: name,
    };
    await httpPost(`${BASE_URL}/category/create`, obj).then((result) => {
      if (result?.data?.status) {
        toast.success(result.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          navigate("/category");
        }, 2000);
        return;
      }
      toast.error(result?.data?.message);
    });
  }

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/category">
                <AiOutlineLeft fa-lg />
                Add Category
              </Link>
            </div>
            <hr />
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="required-FIELD">Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="DeptName"
                  type="text"
                  placeholder={"Enter Category Name"}
                />

                <br />
              </Form.Group>
              <Button
                className="submit_btn"
                type="submit"
                onClick={() => save()}
              >
                Save
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default AddCategory;
