import React, { useState, useEffect } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpGet, httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import DashboardLayout from "../../components/layouts/dashboardLayout";

const EditCategory = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  console.log("params", id);

  const getCategoryById = async () => {
    await httpGet(`${BASE_URL}/category/${id}`)
      .then((res) => {
        setCategory(res?.data?.response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCategoryById();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = {
      catId: id,
      name: name,
    };
    await httpPost(`${BASE_URL}/category/update`, obj)
      .then((res) => {
        console.log("res", res);
        if (res?.data.status) {
          toast.success(res?.data.message);
          setTimeout(() => {
            navigate("/category");
          }, 2000);
        } else if (!res?.data.status) {
          toast.error(res?.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/category">
                <AiOutlineLeft fa-lg />
                Edit Category
              </Link>
            </div>
            <hr />
            <Form onSubmit={(e) => handleSubmit(e)}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="required-FIELD">Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  name="name"
                  type="text"
                  placeholder={category?.name}
                />
                <br />
              </Form.Group>
              <Button className="submit_btn" type="submit">
                Save
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default EditCategory;
