import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";

import { httpDelete, httpGet, httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";

function ProductInquiry() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewDetailsModal, setShowViewDetailsModal] = useState(false);
  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getInquiry = async () => {
    await httpGet(`${BASE_URL}/inquiry/list`)
      .then((res) => {
        if (res?.data?.status) {
          setData(res?.data?.response);
          setIsLoading(false);
          setFilteredData(res?.data?.response);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInquiry();
  }, []);

  const handleShowDeleteModal = (inquiry) => {
    setSelectedInquiry(inquiry);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedInquiry(null);
    setShowDeleteModal(false);
  };

  const handleViewDetails = async (inquiry) => {
    setSelectedInquiry(inquiry);
    setShowViewDetailsModal(true);
  };

  const handleCloseViewDetailsModal = () => {
    setShowViewDetailsModal(false);
  };

  const handleDeleteInquiry = async () => {
    setIsLoading(true);
    await httpDelete(`${BASE_URL}/inquiry/${selectedInquiry?._id}`)
      .then((res) => {
        console.log("res", res);
        if (res?.data?.status === true) {
          getInquiry();
          setIsLoading(false);
          toast.success(res?.data?.message);
          handleCloseDeleteModal();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      name: (
        <h6>
          <b>Name</b>
        </h6>
      ),
      selector: (row) => row?.firstName + " " + row.lastName,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Email</b>
        </h6>
      ),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Phone Number</b>
        </h6>
      ),
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Message</b>
        </h6>
      ),
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleViewDetails(row)}
          >
            {" "}
            <i className="fas fa-eye" style={{ color: "blue" }}></i>
          </button>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleShowDeleteModal(row)}
          >
            {" "}
            <i className="fas fa-trash" style={{ color: "#f00" }}></i>
          </button>
        </div>
      ),
    
    },
  ];

  useEffect(() => {
    if (data?.length > 0) {
      const result = data.filter((item) => {
        return (
          item?.name?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.email?.toLowerCase().includes(search?.toLowerCase()) ||
          item?.phoneNumber?.toLowerCase().includes(search?.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [search, data]);

  return (
    <DashboardLayout>
      <div>
        <DataTable
          title="Product Inquiry"
          columns={columns}
          data={filteredData}
          pagination
          fixedHeader
          selectableRowsHighlight
          progressPending={isLoading}
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />
      </div>

      {/* Delete Inquiry Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Inquiry</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this inquiry?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteInquiry}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showViewDetailsModal} onHide={handleCloseViewDetailsModal}>
        <Modal.Header closeButton>
          <Modal.Title>Inquiry Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>Name:</strong> {selectedInquiry?.name} <br />
          <strong>Email:</strong> {selectedInquiry?.email} <br />
          <strong>Phone Number:</strong> {selectedInquiry?.phoneNumber} <br />
          <strong>Message:</strong> {selectedInquiry?.message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseViewDetailsModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </DashboardLayout>
  );
}

export default ProductInquiry;
