/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Col, Container, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import Form from "react-bootstrap/Form";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpGet, httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import Loader from "../../components/loader/loader";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import TagButton from "../../components/buttons/tagButton";
import * as Yup from "yup";
import { useFormik } from "formik";

const AddUpdateMetalType = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [typeValue, setTypeValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = () => {
    const updatedType = [...formik.values.metalsType, typeValue];
    formik.setFieldValue("metalsType",updatedType );
    setTypeValue("");
  };

  const handleRemove = (index) => {
    const updatedType = [...formik.values.metalsType];
    updatedType.splice(index, 1);
    formik.setFieldValue("metalsType",updatedType );
  };

  useEffect(() => {
    getCategory();
    if (id) {
      getDataById();
    }
  }, []);

 

  const getDataById = async () => {
    setIsLoading(true);
    try {
      await httpGet(`${BASE_URL}/metal/${id}`)
        .then((res) => {
          let response = res?.data?.response;
          console.log("response", response);
          setIsLoading(false);
          formik.setFieldValue("cateId", response?.cateId?._id )
          formik.setFieldValue("barandId",response?.barandId?._id )
          formik.setFieldValue("metalsName", response?.metalsName )
          formik.setFieldValue("metalsType", response?.metalsType)
          getBrandsByCategoryId(response.cateId?._id);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const getBrandsByCategoryId = async (cateId) => {
    try {
      await httpGet(`${BASE_URL}/brand/category/${cateId}`).then((res) => {
        if (res?.data?.status) {
          setBrandList(res?.data?.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      await httpGet(`${BASE_URL}/category/list`).then((res) => {
        if (res?.data?.status) {
          console.log("res", res);
          setCategoryList(res.data.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      cateId: "",
      barandId: "",
      metalsName: "",
      metalsType: [],
    },
    validationSchema: Yup.object({
      cateId: Yup.string().required("Category is required"),
      barandId: Yup.string().required("Brand is required"),
      metalsName: Yup.string().required("Metal Name is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      let obj = formik.values;

      if(id) {
        obj._id = id
      }
    
      let endPoint = id ? '/metal/update' : '/metal/create';
      await httpPost(`${BASE_URL}${endPoint}`, obj).then((result) => {
        if (result?.data?.status) {
          toast.success(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/metalType");
          setIsLoading(false);
          return;
        } else {
          toast.error(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        }
      });
    },
  });

  useEffect(() => {
    if(formik.values.cateId !== "") getBrandsByCategoryId(formik.values.cateId)
  }, [formik.values.cateId])

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/metalType">
                <AiOutlineLeft fa-lg />
                {id ? " Update Metal Type" : " Add Metal Type"}
              </Link>
            </div>
            <hr />
            {isLoading && <Loader />}
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">
                      Select Category
                    </Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      name="cateId"
                      value={formik.values.cateId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option>Select Category</option>
                      {categoryList.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                    {formik.touched.cateId && formik.errors.cateId ? (
                      <div className="error">
                        {formik.errors.cateId}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">
                      Select Brand
                    </Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      name="barandId"
                      value={formik.values.barandId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option>Select Brand</option>
                      {brandList.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.brandName}
                        </option>
                      ))}
                    </Form.Select>
                    {formik.touched.barandId && formik.errors.barandId ? (
                      <div className="error">
                        {formik.errors.barandId}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="required-FIELD">Metal Name</Form.Label>
                <Form.Control
                  value={formik.values.metalsName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="metalsName"
                  type="text"
                  placeholder={"Enter Metal Name"}
                />
                {formik.touched.metalsName && formik.errors.metalsName ? (
                  <div className="error">
                    {formik.errors.metalsName}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="required-FIELD">
                  Add New Metal Type
                </Form.Label>
                <Form.Control
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                  name="metalsType"
                  type="text"
                  placeholder={"Enter Metal Type"}
                />
                {formik.values?.metalsType?.map((type, index) => (
                  <TagButton key={index} label={type} onDelete={handleRemove} />
                ))}
                <div className="d-flex justify-content-end">
                  <Button
                    style={{
                      width: "unset",
                      backgroundColor: "black",
                      color: "white",
                      cursor: "pointer",
                    }}
                    type="button"
                    onClick={handleAdd}
                  >
                    Add Metal Type
                  </Button>
                </div>
              </Form.Group>

              <Button className="submit_btn" type="submit">
                {id ? "Update" : "Save"}
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default AddUpdateMetalType;
