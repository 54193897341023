import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import Form from "react-bootstrap/Form";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpGet, httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import Loader from "../../components/loader/loader";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import TagButton from "../../components/buttons/tagButton";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import IconButton from "@mui/material/IconButton";
import * as Yup from "yup";
import { useFormik } from "formik";

const AddUpdateJeweleryType = () => {
  const imageRef = React.useRef();
  const { id } = useParams();

  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [typeValue, setTypeValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    cateId: "",
    barandId: "",
    jewelleryName: "",
    jewelleryType: [],
    image: "",
  });
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);

    // Image preview
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const deleteImage = () => {
    imageRef.current.value = "";
    setImageFile(null);
    setImagePreview(null);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === "cateId") {
      getBrandsByCategoryId(value);
    }
  };

  useEffect(() => {
    getCategory();
    if (id) {
      getDataById();
    }
  }, []);

  const getDataById = async () => {
    try {
      await httpGet(`${BASE_URL}/jewelry/${id}`)
        .then((res) => {
          let response = res?.data?.response;
          if(response) {

            formik.setFieldValue("cateId", response?.cateId?._id);
            formik.setFieldValue("barandId", response?.barandId?._id);
            formik.setFieldValue("jewelleryName", response?.jewelleryName);
            formik.setFieldValue("jewelleryType", response?.jewelleryType);

            setUpdateData(response);
            getBrandsByCategoryId(response.cateId?._id);
            setImagePreview(response?.image);
          }
        
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const getBrandsByCategoryId = async (cateId) => {
    try {
      await httpGet(`${BASE_URL}/brand/category/${cateId}`).then((res) => {
        if (res?.data?.status) {
          setBrandList(res?.data?.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCategory = async () => {
    try {
      await httpGet(`${BASE_URL}/category/list`).then((res) => {
        if (res?.data?.status) {
          setCategoryList(res.data.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddJewelryType = () => {
    if (typeValue) {
      const updatedJewelryTypes = [...formik.values.jewelleryType, typeValue];
      formik.setFieldValue("jewelleryType", updatedJewelryTypes);
      setTypeValue("");
    }
  };

  const handleRemoveJewelryType = (index) => {
    const updatedJewelryTypes = [...formik.values.jewelleryType];
    updatedJewelryTypes.splice(index, 1);
    formik.setFieldValue("jewelleryType", updatedJewelryTypes);
    // setData({ ...data, jewelleryType: updatedJewelryTypes });
  };

  const formik = useFormik({
    initialValues: {
      cateId: "",
      barandId: "",
      jewelleryName: "",
      jewelleryType: [],
      image: "",
    },
    validationSchema: Yup.object({
      cateId: Yup.string().required("Category is required"),
      barandId: Yup.string().required("Brand is required"),
      jewelleryName: Yup.string().required("Jewelry Name is required"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const formData = new FormData();
        id && formData.append("_id", id);
        formData.append("cateId", values.cateId);
        formData.append("barandId", values.barandId);
        formData.append("jewelleryName", values.jewelleryName);
        formik.values.jewelleryType.forEach((type, index) => {
          formData.append(`jewelleryType[${index}]`, type);
        });

        if (imageFile) {
          formData.append("image", imageFile);
        }

        let endPoint = id ? "/jewelry/update" : "/jewelry/create";

        await httpPost(`${BASE_URL}${endPoint}`, formData).then((result) => {
          if (result.data.status) {
            toast.success(result.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
            navigate("/jeweleryType");
          } else {
            toast.error(result.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (formik.values.cateId !== "") {
      getBrandsByCategoryId(formik.values.cateId);
    }
  }, [formik.values.cateId]);

  return (
    <DashboardLayout>
      <Container>
        <div className="admin-main">
          <div className="titles_backBtn">
            <Link to="/jeweleryType">
              <AiOutlineLeft fa-lg />
              {id ? " Update Jewelry Type" : " Add Jewelry Type"}
            </Link>
          </div>
          <hr />
          {isLoading && <Loader />}
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required-FIELD">
                    Select Category
                  </Form.Label>

                  <Form.Select
                    aria-label="Default select example"
                    name="cateId"
                    value={formik.values.cateId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option>Select Category</option>
                    {categoryList.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                  {formik.touched.cateId && formik.errors.cateId ? (
                    <div className="error">{formik.errors.cateId}</div>
                  ) : null}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required-FIELD">
                    Select Brand
                  </Form.Label>

                  <Form.Select
                    aria-label="Default select example"
                    name="barandId"
                    value={formik.values.barandId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option>Select Brand</option>
                    {brandList.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.brandName}
                      </option>
                    ))}
                  </Form.Select>
                  {formik.touched.barandId && formik.errors.barandId ? (
                    <div className="error">{formik.errors.barandId}</div>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required-FIELD">
                    Jewelry Type
                  </Form.Label>
                  <Form.Control
                    value={formik.values.jewelleryName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="jewelleryName"
                    type="text"
                    placeholder={"Enter Jewelry Name"}
                  />
                  {formik.touched.jewelleryName &&
                  formik.errors.jewelleryName ? (
                    <div className="error">{formik.errors.jewelleryName}</div>
                  ) : null}
                </Form.Group>
              </Col>

              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required-FIELD">
                    Jewelry Image
                  </Form.Label>
                  <Form.Control
                    onChange={handleImageChange}
                    type="file"
                    ref={imageRef}
                    value={undefined}
                  />

                  {imagePreview && (
                    <div className="image-area">
                      <img src={imagePreview} alt="Preview" />
                      <span className="remove-image">
                        <IconButton size="small" onClick={deleteImage}>
                          <DeleteForeverSharpIcon />
                        </IconButton>
                      </span>
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="required-FIELD">
                    Jewelry Sub Type
                  </Form.Label>
                  <Form.Control
                    value={typeValue}
                    onChange={(e) => setTypeValue(e.target.value)}
                    name="jewelleryType"
                    type="text"
                    placeholder={"Enter Jewelry Type"}
                  />
                  {formik.values?.jewelleryType?.map((type, index) => (
                    <TagButton
                      key={index}
                      label={type}
                      onDelete={handleRemoveJewelryType}
                    />
                  ))}
                  <div className="d-flex justify-content-end">
                    <Button
                      style={{
                        width: "unset",
                        backgroundColor: "black",
                        color: "white",
                        cursor: "pointer",
                      }}
                      type="button"
                      onClick={handleAddJewelryType}
                    >
                      Add Jewelry Type
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Button className="submit_btn" type="submit">
              {id ? "Update" : "Save"}
            </Button>
          </Form>
        </div>
      </Container>
    </DashboardLayout>
  );
};

export default AddUpdateJeweleryType;
