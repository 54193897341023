import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import { BASE_URL } from "../../config/endPoint";
import { httpDelete, httpGet, httpPost, httpPut } from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";

function MaterialTable() {
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleShowDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };
    const [showAddModal, setShowAddModal] = useState(false);
    const handleCloseAddModal = () => setShowAddModal(false);
    const handleShowAddModal = () => {
        setShowAddModal(true);
        formikAdd.resetForm();
    };
    const [showEditModal, setShowEditModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const handleCloseEditModal = () => setShowEditModal(false);
    const handleShowEditModal = (item) => {
        setEditItem(item);
        formikEdit.setFieldValue("name", item?.name);
        formikEdit.setFieldValue("type", item?.type);
        setShowEditModal(true);
    };

    const formikAdd = useFormik({
        initialValues: {
            name: "",
            type: ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            type: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            handleAddLensMaterial(values);
        },
    });

    const formikEdit = useFormik({
        initialValues: {
            name: editItem ? editItem.name : "",
            type: editItem ? editItem.type : ""
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            type: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            handleEditLensMaterial(editItem._id, values);
        },
    });

    useEffect(() => {
        getData("");
    }, []);

    const getData = async (value) => {
    try {
            let typeparams = "";
            if (value !== "") {
                typeparams = value;
            }
            const response = await httpGet(`${BASE_URL}/materials?type=${typeparams}`);
            if (response?.data?.status) {
                setIsLoading(false);
                setData(response?.data.data);
            }
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    };

    const deleteItem = async () => {
        try {
            const response = await httpDelete(`${BASE_URL}/material/${deleteId}`);
            if (response?.data?.status) {
                setDeleteId("");
                getData("");
                toast.success(response?.data?.message);
                setShowDeleteModal(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddLensMaterial = async (values) => {
        try {
            setIsLoading(true);
            const response = await httpPost(`${BASE_URL}/material`, values);
            if (response?.data?.status) {
                toast.success(response?.data?.message);
                getData("");
                handleCloseAddModal();
            } else {
                toast.error(response?.data?.message);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
            toast.error("Failed to add");
        }
    };

    const handleEditLensMaterial = async (id, values) => {
        try {
            setIsLoading(true);
            const response = await httpPut(`${BASE_URL}/material/${id}`, values);
            if (response?.data?.status) {
                toast.success(response?.data?.message);
                getData("");
                handleCloseEditModal();
            } else {
                toast.error(response?.data?.message);
            }
            setIsLoading(false);
        } catch (error) {
            console.error("Error:", error);
            setIsLoading(false);
            toast.error("Failed to edit");
        }
    };

    const colunms = [
        {
            name: (
                <h6>
                    <b>Material</b>
                </h6>
            ),
            selector: (row) => row.name,
            sortable: true,
        },
        {
            name: (
                <h6>
                    <b>Type</b>
                </h6>
            ),
            selector: (row) => row.type,
            sortable: true,
        },
        {
            name: (
                <h6>
                    <b>Action</b>
                </h6>
            ),
            cell: (row) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "50px",
                    }}
                >
                    <button
                        onClick={() => handleShowEditModal(row)}
                        style={{ border: "none", backgroundMaterial: "transparent" }}
                    >
                        {" "}
                        <i className="fa-solid fa-pen fa-lg" style={{ Material: "blue" }}></i>
                    </button>
                    <button
                        style={{ border: "none", backgroundMaterial: "transparent" }}
                        type="button"
                        onClick={() => handleShowDeleteModal(row._id)}
                    >
                        {" "}
                        <i className="fas fa-trash" style={{ Material: "#f00" }}></i>
                    </button>
                </div>
            ),
        },
    ];

    const actions = (
        <>
            <Form.Control
                as="select"
                name="type"
                className="w-25"
                onChange={(event) => getData(event.target.value)}
            >
                <option>Select type</option>
                <option value="">All</option>
                <option value="frame">Frame</option>
                <option value="lens">Lens</option>
                <option value="case">Case</option>
                <option value="strap/bracelet">Strap/Bracelet</option>
            </Form.Control>
            <Button className="add-btn m-0 ms-2" type="button" onClick={handleShowAddModal}>
                Add Material
            </Button>
        </>
    );

    return (
        <DashboardLayout>
            <div className="table-outer">
                <DataTable
                    title="Material"
                    columns={colunms}
                    data={data}
                    pagination
                    fixedHeader
                    progressPending={isLoading}
                    selectableRowsHighlight
                    actions={actions}
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                        <>
                            <input
                                type="text"
                                placeholder="Search here"
                                className="  form-control"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                        </>
                    }
                />

                <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDeleteModal}>
                            No
                        </Button>
                        <Button variant="primary" onClick={deleteItem}>
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showAddModal} onHide={handleCloseAddModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Material</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={formikAdd.handleSubmit}>
                            <Form.Group controlId="formBasicType">
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={formikAdd.values.type}
                                    onChange={formikAdd.handleChange}
                                    onBlur={formikAdd.handleBlur}
                                    name="type"
                                >
                                    <option value="">Select type</option>
                                    <option value="frame">Frame</option>
                                    <option value="lens">Lens</option>
                                    <option value="case">Case</option>
                                    <option value="strap/bracelet">Strap/Bracelet</option>
                                </Form.Control>
                                {formikAdd.touched.type && formikAdd.errors.type ? (
                                    <div className="error">{formikAdd.errors.type}</div>
                                ) : null}
                            </Form.Group>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Material Name"
                                    value={formikAdd.values.name}
                                    onChange={formikAdd.handleChange}
                                    onBlur={formikAdd.handleBlur}
                                    name="name"
                                />
                                {formikAdd.touched.name && formikAdd.errors.name ? (
                                    <div className="error">{formikAdd.errors.name}</div>
                                ) : null}
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Add
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showEditModal} onHide={handleCloseEditModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Material</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={formikEdit.handleSubmit}>
                            <Form.Group controlId="formBasicType">
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={formikAdd.values.type}
                                    onChange={formikAdd.handleChange}
                                    onBlur={formikAdd.handleBlur}
                                    name="type"
                                >
                                    <option value="">Select type</option>
                                    <option value="frame">Frame</option>
                                    <option value="lens">Lens</option>
                                    <option value="case">Case</option>
                                    <option value="strap/bracelet">Strap/Bracelet</option>
                                </Form.Control>
                                {formikAdd.touched.type && formikAdd.errors.type ? (
                                    <div className="error">{formikAdd.errors.type}</div>
                                ) : null}
                            </Form.Group>
                            <Form.Group controlId="formBasicName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Material name"
                                    value={formikEdit.values.name}
                                    onChange={formikEdit.handleChange}
                                    onBlur={formikEdit.handleBlur}
                                    name="name"
                                />
                                {formikEdit.touched.name && formikEdit.errors.name ? (
                                    <div className="error">{formikEdit.errors.name}</div>
                                ) : null}
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Update
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </DashboardLayout>
    );
}

export default MaterialTable;
