import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { httpGet, httpPut } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DashboardLayout from "../../components/layouts/dashboardLayout";

function GrabbedOffers() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [listStatus, setListStatus] = useState("pending");
  const [selectedNote, setSelectedNote] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setId(id);
    setShow(true);
  };

  const getList = async () => {
    setIsLoading(true);
    await httpGet(`${BASE_URL}/grabbed_offers?status=${listStatus}`)
      .then((res) => {
        if (res?.data?.status) {
          setData(res?.data?.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getList();
  }, []);

  useEffect(() => {
    getList();
  }, [listStatus]);

  const handleStatusChange = (value) => {
    setListStatus(value);
  };

  const columns = [
    {
      name: (
        <h6>
          <b>User Email</b>
        </h6>
      ),
      selector: (row) => row.userEmail,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Unique Code</b>
        </h6>
      ),
      selector: (row) => row.uniqueCode,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Status</b>
        </h6>
      ),
      selector: (row) => row.status,
      sortable: true,
      //   cell: (row) => (
      //     <div>
      //       <select
      //         value={selectedStatus}
      //         onChange={(e) => setSelectedStatus(e.target.value)}
      //       >
      //         <option value="in_progress">In Progress</option>
      //         <option value="cancel">Cancel</option>
      //         <option value="active">Active</option>
      //         <option value="completed">Completed</option>
      //       </select>
      //       <Button
      //         variant="primary"
      //         onClick={() => handleUpdateStatus(row._id, selectedStatus)}
      //       >
      //         Update Status
      //       </Button>
      //     </div>
      //   ),
    },

    {
      name: (
        <h6>
          <b>Note</b>
        </h6>
      ),
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Date</b>
        </h6>
      ),
      selector: (row) => moment(row.createdAt).format("DD MM YYYY"),
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      selector: (row) => row.action,
      // right: true,
      cell: (row) => (
        <>
          <Button
            variant="primary"
            className="update-btn"
            onClick={() => {
              setId(row._id);
              setSelectedStatus("");
              setSelectedNote("");
              setShow(true);
            }}
          >
            Update
          </Button>
        </>
      ),
    },
  ];

  const handleNoteChange = (e) => {
    setSelectedNote(e.target.value);
  };

  const handleUpdateStatusAndNote = async () => {
    try {
      await httpPut(`${BASE_URL}/grabbed_offer/${id}`, {
        status: selectedStatus,
        note: selectedNote,
      });

      handleClose();

      getList();

      toast.success("Status and Note updated successfully");
    } catch (error) {
      console.error(error);
      // Handle error, show error toast, etc.
    }
  };

  const customStyles = {
    tableWrapper: {
      style: {
        display: "table",
        paddingLeft: "16px",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const filteredItems = data.filter(
    (item) =>
      item.brandName &&
      item.brandName.toLowerCase().includes(search.toLowerCase())
  );

  const actions = (
    <Form.Select
      onChange={(e) => handleStatusChange(e.target.value)}
      aria-label="Default select example"
    >
      <option>Select Status</option>
      <option value="active">Active</option>
      <option value="in_progress">Pending</option>
      <option value="completed">Completed</option>
      <option value="cancel">Cancelled</option>
    </Form.Select>
  );

  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          title="Grabbed Offers List"
          columns={columns}
          data={data}
          pagination
          fixedHeader
          actions={actions}
          customStyles={customStyles}
          progressPending={isLoading}
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Status and Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Status:</Form.Label>
              <select
                className="w-100"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="in_progress">In Progress</option>
                <option value="canceled">Cancelled</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
              </select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Note:</Form.Label>
              <Form.Control
                type="text"
                value={selectedNote}
                onChange={handleNoteChange}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleUpdateStatusAndNote}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default GrabbedOffers;
