import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container, Image, Modal } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import Form from "react-bootstrap/Form";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpGet, httpPostFormData, httpPutFormData } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import Loader from "../../components/loader/loader";
import leftImg from "../../images/offerleft.png";
import logo from "../../images/logo.png";
import DashboardLayout from "../../components/layouts/dashboardLayout";

const AddUpdateOffer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    categoryId: "",
    title: "",
    description: "",
    percentageText: "",
    couponCode: "",
    offerEndDate: "",
    image: "",
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    if (id) {
      getOfferById();
    }
    getCategory();
  }, []);

  const handleImageChange = (e, key) => {
    const file = e.target.files[0];
    setData({ ...data, [key]: file });
  };

  const getCategory = async () => {
    try {
      await httpGet(`${BASE_URL}/category/list`).then((res) => {
        if (res?.data?.status) {
          console.log("res", res);
          setCategoryList(res.data.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getOfferById = async () => {
    setIsLoading(true);
    await httpGet(`${BASE_URL}/offer/${id}`)
      .then((res) => {
        console.log("res", res);
        setIsLoading(false);
        setData({
          categoryId: res?.data?.data?.category?._id || "",
          title: res?.data?.data?.title || "",
          description: res?.data?.data?.description || "",
          offerEndDate: res?.data?.data?.offerEndDate || "",
          percentageText: res?.data?.data?.percentageText || "",
          couponCode: res?.data?.data?.couponCode || "",
          image: res?.data?.data?.image,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let formData = new FormData();
    formData.append("categoryId", data.categoryId);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("percentageText", data.percentageText);
    formData.append("couponCode", data.couponCode);
    formData.append("offerEndDate", data.offerEndDate);
    formData.append("brandLogo", data.brandLogo);
    formData.append("image", data.image);

    try {
      const result = await (id
        ? httpPutFormData(`${BASE_URL}/offer/${id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
        : httpPostFormData(`${BASE_URL}/offer/create`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }));

      setIsLoading(false);

      if (result.data.status) {
        toast.success(result.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/offers");
      } else {
        toast.error(result.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/offers">
                <AiOutlineLeft fa-lg />
                Back
              </Link>
            </div>
            <hr />
            {isLoading && <Loader />}
            <Form onSubmit={handleSubmit}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="required-FIELD">
                  Select Category
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="categoryId"
                  onChange={(e) => handleChange(e)}
                  value={data.categoryId}
                >
                  <option>Select Category</option>
                  {categoryList.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label className="required-FIELD">Offer Title</Form.Label>
                <Form.Control
                  value={data.title}
                  onChange={(e) => handleChange(e)}
                  name="title"
                  type="text"
                  placeholder={"Enter Offer Title"}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label className="required-FIELD">
                  Offer Description
                </Form.Label>
                <Form.Control
                  onChange={(e) => handleChange(e)}
                  value={data.description}
                  name="description"
                  type="text"
                  placeholder={"Enter Offer Description"}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label className="required-FIELD">
                  Offer Percentage
                </Form.Label>
                <Form.Control
                  onChange={(e) => handleChange(e)}
                  value={data.percentageText}
                  name="percentageText"
                  type="text"
                  placeholder={"Enter Offer Percentage"}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput5"
              >
                <Form.Label className="required-FIELD">Coupon Code</Form.Label>
                <Form.Control
                  onChange={(e) => handleChange(e)}
                  value={data.couponCode}
                  name="couponCode"
                  type="text"
                  placeholder={"Enter Coupon Code"}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput6"
              >
                <Form.Label className="required-FIELD">
                  Offer End Date
                </Form.Label>
                <Form.Control
                  onChange={(e) => handleChange(e)}
                  value={
                    data.offerEndDate ? data.offerEndDate.split("T")[0] : ""
                  }
                  name="offerEndDate"
                  type="date"
                  min={new Date().toISOString().split("T")[0]}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput7"
              >
                <Form.Label className="required-FIELD">Offer Image</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "image")}
                  required
                />
              </Form.Group>

              <Button className="submit_btn" type="button" onClick={handleShow}>
                Preview
              </Button>
              <Button className="submit_btn" type="submit">
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </Form>
          </div>
          {/* offer popup preview  */}

          <Modal
            className="offer-modal"
            show={show}
            onHide={handleClose}
            size="lg"
            centered
          >
            <Modal.Body>
              <div className="offerModalOuter">
                <Button onClick={handleClose} className="close-btnn">
                  <i className="fa-solid fa-xmark"></i>
                </Button>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <Image
                    className="offer-image"
                    src={data?.image ? data?.image : leftImg}
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="offer-right-text px-4 py-4">
                    <h3>{data.title}</h3>
                    <h1>{data.percentageText}</h1>
                    <h3>{data.description}</h3>
                    <Form className="my-4 offer-form">
                      <Form.Control
                        type="email"
                        placeholder="Enter you mail address"
                        className="mb-3"
                      />
                      <Button className="content-btn">CLAIM OFFER</Button>
                    </Form>
                    <div className="text-center">
                      <p className="mb-1">OR</p>
                      <Button className="decline-btn">Decline Offer</Button>
                    </div>
                    <div className="offer-logo text-center mt-4">
                      <Image src={logo} />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default AddUpdateOffer;
