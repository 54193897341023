import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Header from "../common/header";
import SideBar from "../common/sidebar";
import "./dashboardLayout.css";

const DashboardLayout = ({ children }) => {
  const stringExist = localStorage.getItem("isSideBarOpen");
  const [isOpen, setIsOpen] = useState(stringExist === "false" ? false : true);
  const sidebarWidth = isOpen ? "225px" : "85px";

  const toggle = () => {
    setIsOpen(!isOpen);
    localStorage.setItem("isSideBarOpen", `${!isOpen}`);
  };

  useEffect(() => {
    if (stringExist === null) {
      localStorage.setItem("isSideBarOpen", "true");
    }
  }, []);

  return (
    <div>
      <Header toggle={toggle} />
      <motion.div
        initial={false}
        animate={{
          width: sidebarWidth,
          transition: {
            duration: 0.1,
            type: "spring",
            damping: 5,
          },
        }}
        className={`sidebar`}
      >
        <SideBar toggle={toggle} isOpen={isOpen} setIsOpen={setIsOpen} />
      </motion.div>
      <div style={{ marginLeft: sidebarWidth, padding: "1rem", marginTop: 90 }}>
        {children}
      </div>
    </div>
  );
};

export default DashboardLayout;
