import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import SelectInput from "../../components/selectInput";
import { BASE_URL } from "../../config/endPoint";
import { httpGet, httpPost } from "../../utils/api";
import {
  FRAME_FIT,
  FRAME_STYLE,
  diamondShapes,
  sizeRanges,
  watchMovements,
  watchWaterResistance,
} from "../../utils/formData";
import { Modal, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../components/loader/loader";
import Select from "react-select";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AddUpdateProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [files, setFiles] = useState([]);
  const [metalData, setMetalData] = useState([]);
  const [jeweleryData, setJeweleryData] = useState([]);
  const [stoneList, setStoneList] = useState([]);
  const [diamonds, setDiamonds] = useState([]);
  const [colors, setColors] = useState([]);
  const [materials, setMaterials] = useState([]);


  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (key, e) => {
    let { value } = e.target;

    formik.setFieldValue(key, value);

    if (key === "cateId") {
      getBrandsByCategoryId(value);
      getStones();
      setSelectedCategory(e.target.selectedOptions[0].label);
    }

    if (key === "barandId") {
      getMetalData(value);
      getJewelryData(value);
    }

  };

  useEffect(() => {
    getCategory();
    getColorsList();
    getMaterialsList();
    getJewelryDiamondsList();
    if (id) {
      getSingleProductData();
    }
  }, []);

  const getSingleProductData = async () => {
    setIsLoading(true);
    try {
      await httpGet(`${BASE_URL}/product/${id}`).then((res) => {
        if (res?.data?.status) {
          console.log("response data", res?.data?.response);

          formik.setFieldValue("cateId", res?.data?.response?.cateId?._id);
          formik.setFieldValue("barandId", res?.data?.response?.barandId);
          formik.setFieldValue("productName", res?.data?.response?.productName);
          formik.setFieldValue("description", res?.data?.response?.description);
          formik.setFieldValue("price", res?.data?.response?.price);
          formik.setFieldValue("mrNo", res?.data?.response?.mrNo);
          formik.setFieldValue("size", res?.data?.response?.size[0] || "");
          formik.setFieldValue(
            "bracelet_material",
            res?.data?.response?.bracelet_material
          );
          formik.setFieldValue("movement", res?.data?.response?.movement);
          formik.setFieldValue("diamonds", res?.data?.response?.diamonds);
          formik.setFieldValue("gender", res?.data?.response?.gender);

          formik.setFieldValue(
            "case_material",
            res?.data?.response?.case_material || ""
          );
          formik.setFieldValue(
            "case_size",
            res?.data?.response?.case_size || ""
          );
          formik.setFieldValue(
            "waterResistance",
            res?.data?.response?.waterResistance || ""
          );

          formik.setFieldValue(
            "jewellery_metal",
            res?.data?.response?.jewellery_metal_id || ""
          );
          formik.setFieldValue(
            "jewellery_Type",
            res?.data?.response?.jewellery_Type_id || ""
          );
          formik.setFieldValue(
            "jewellery_types",
            res?.data?.response?.jewellery_types || ""
          );
          formik.setFieldValue(
            "metal_types",
            res?.data?.response?.metal_types || ""
          );
          formik.setFieldValue(
            "jewellery_cut",
            res?.data?.response?.jewellery_cut || ""
          );
          formik.setFieldValue(
            "jewellery_color",
            res?.data?.response?.jewellery_color || ""
          );
          formik.setFieldValue(
            "jewellery_diamonds",
            res?.data?.response?.jewellery_diamonds || ""
          );
          formik.setFieldValue(
            "jewellery_stone",
            res?.data?.response?.jewellery_stone || ""
          );

          // ************* Sunglass Form Data ***********************
          formik.setFieldValue(
            "frame_color",
            res?.data?.response?.frame_color || ""
          );
          formik.setFieldValue(
            "lens_color",
            res?.data?.response?.lens_color || ""
          );
          formik.setFieldValue(
            "frame_style",
            res?.data?.response?.frame_style || ""
          );
          formik.setFieldValue(
            "frame_material",
            res?.data?.response?.frame_material || ""
          );
          formik.setFieldValue("colour", res?.data?.response?.colour || "");
          formik.setFieldValue(
            "lens_material",
            res?.data?.response?.lens_material || ""
          );
          formik.setFieldValue("fit", res?.data?.response?.fit || "");

          getBrandsByCategoryId(res?.data?.response?.cateId?._id);
          getJewelryData(res?.data?.response?.barandId);
          getMetalData(res?.data?.response?.barandId);
          setSelectedCategory(res?.data?.response?.cateId?.name);

          res?.data?.response?.images.length > 0 &&
            res?.data?.response?.images.forEach((data) => {
              setFiles([{ url: data }]);
            });

          setIsLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const getCategory = async () => {
    try {
      await httpGet(`${BASE_URL}/category/list`).then((res) => {
        if (res?.data?.status) {
          setCategoryList(res.data.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStones = async () => {
    try {
      await httpGet(`${BASE_URL}/stone/list`).then((res) => {
        if (res?.data?.status) {
          setStoneList(res.data.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getJewelryDiamondsList = async () => {
    try {
      await httpGet(`${BASE_URL}/diamonds`).then((res) => {
        if (res?.data?.status) {
          console.log(("res", res?.data?.data))
          setDiamonds(res.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMaterialsList = async () => {
    try {
      await httpGet(`${BASE_URL}/materials`).then((res) => {
        if (res?.data?.status) {
          setMaterials(res.data.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getColorsList = async () => {
    try {
      const res = await httpGet(`${BASE_URL}/colors`);
      if (res?.data?.status) {
        setColors(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);

      console.log(error);
    }
  };

  const getBrandsByCategoryId = async (id) => {
    try {
      await httpGet(`${BASE_URL}/brand/category/${id}`).then((res) => {
        if (res?.data?.status) {
          console.log("res", res);
          setBrandList(res?.data?.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getMetalData = async (id) => {
    await httpGet(`${BASE_URL}/metal/brand/${id}`)
      .then((res) => {
        if (res?.data?.status) {
          setMetalData(res.data.response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getJewelryData = async (id) => {
    await httpGet(`${BASE_URL}/jewelry/brand/${id}`)
      .then((res) => {
        if (res?.data?.status) {
          setJeweleryData(res.data.response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url.lastIndexOf("/") + 1) || ""
    );
  };

  const handleChangeImage = ({ fileList: newFileList }) => {
    setFiles(newFileList);
  };

  console.log("files", files);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const formik = useFormik({
    initialValues: {
      cateId: "",
      barandId: "",
      productName: "",
      description: "",
      price: null,
      files: "",
      mrNo: null,
      gender: "",
      jewellery_Type: "",
      jewellery_types: "",
      jewellery_metal: "",
      metal_types: [],
      jewellery_cut: "",
      jewellery_color: "",
      jewellery_diamonds: "",
      jewellery_stone: "",
      bracelet_material: "",
      movement: "",
      waterResistance: "",
      case_size: "",
      case_material: "",
      diamonds: "",
      barandTag: "",
      priceOnOff: true,
      frame_style: "",
      frame_color: "",
      lens_color: "",
      frame_material: "",
      lens_material: "",
      fit: "",
      colour: "",
      stone: "",
    },
    validationSchema: Yup.object().shape({
      cateId: Yup.string().required("Category is required"),
      barandId: Yup.string().required("Brand is required"),
      productName: Yup.string().required("Product Name is required"),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      setIsLoading(true);
      for (let i = 0; i < files.length; i++) {
        if (files[i]?.originFileObj) {
          formData.append("files", files[i].originFileObj);
        } else if (files[i].url) {
          formData.append("files", files[i].url);
        }
      }

      formik.values.cateId && formData.append("cateId", formik.values.cateId);
      formik.values.barandId &&
        formData.append("barandId", formik.values.barandId);
      formik.values.productName &&
        formData.append("productName", formik.values.productName);
      formik.values.description &&
        formData.append("description", formik.values.description);
      formik.values.price && formData.append("price", formik.values.price);
      formik.values.mrNo && formData.append("mrNo", formik.values.mrNo);
      formik.values.size && formData.append("size", formik.values.size);
      formik.values.bracelet_material &&
        formData.append("bracelet_material", formik.values.bracelet_material);
      formik.values.movement &&
        formData.append("movement", formik.values.movement);
      formik.values.diamonds &&
        formData.append("diamonds", formik.values.diamonds);
      formik.values.gender && formData.append("gender", formik.values.gender);

      formik.values.case_material &&
        formData.append("case_material", formik.values.case_material || "");
      formik.values.case_size &&
        formData.append("case_size", formik.values.case_size || "");
      formik.values.waterResistance &&
        formData.append("waterResistance", formik.values.waterResistance || "");

      formik.values.jewellery_metal &&
        formData.append(
          "jewellery_metal_id",
          formik.values.jewellery_metal || ""
        );
      formik.values.jewellery_Type &&
        formData.append(
          "jewellery_Type_id",
          formik.values.jewellery_Type || ""
        );
      formik.values.jewellery_types &&
        formData.append("jewellery_types", formik.values.jewellery_types || "");
      formik.values.metal_types &&
        formData.append("metal_types", formik.values.metal_types || "");
      (formik.values.jewellery_cut) &&
        formData.append("jewellery_cut", formik.values.jewellery_cut || "");
      formik.values.jewellery_color &&
        formData.append("jewellery_color", formik.values.jewellery_color || "");
      formik.values.jewellery_diamonds &&
        formData.append(
          "jewellery_diamonds",
          formik.values.jewellery_diamonds || ""
        );
      formik.values.jewellery_stone &&
        formData.append("jewellery_stone", formik.values.jewellery_stone || "");

      // ************* Sunglass Form Data ***********************

      formik.values.frame_color &&
        formData.append("frame_color", formik.values.frame_color || "");
      formik.values.lens_color &&
        formData.append("lens_color", formik.values.lens_color || "");
      formik.values.frame_style &&
        formData.append("frame_style", formik.values.frame_style || "");
      formik.values.frame_material &&
        formData.append("frame_material", formik.values.frame_material || "");
      formik.values.colour &&
        formData.append("colour", formik.values.colour || "");
      formik.values.lens_material &&
        formData.append("lens_material", formik.values.lens_material || "");
      formik.values.fit && formData.append("fit", formik.values.fit || "");

      id && formData.append("productId", id || "");

      let endPoint = id ? `/product/update` : `/product/create`;

      try {
        const result = await httpPost(`${BASE_URL}${endPoint}`, formData);
        if (result?.data?.status) {
          toast.success(result.data.message);
          navigate("/products");
          setIsLoading(false);
          return;
        }
        toast.error(result.data.message);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    },
  });


  const handleSelectChange = (keyName, selectedOptions) => {
    const selectedValues = selectedOptions.map(option => option.value);
    console.log("selectedValues", selectedValues);
    formik.setFieldValue(keyName, selectedValues);
  }

  console.log("formik", formik.values);

  return (
    <DashboardLayout>
      {isLoading && <Loader />}
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/products">
                <AiOutlineLeft fa-lg />
                {id ? "Edit Product" : "Add Product"}
              </Link>
            </div>
            <hr />
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">
                      Select Category
                    </Form.Label>

                    <Form.Select
                      aria-label="Default select example"
                      name="cateId"
                      onChange={(e) => handleChange("cateId", e)}
                      onBlur={formik.handleBlur}
                      value={formik.values.cateId}
                    >
                      <option>Select Category</option>
                      {categoryList.map((option) => (
                        <option key={option._id} value={option._id}>
                          {option.name}
                        </option>
                      ))}
                    </Form.Select>
                    {formik.errors.cateId && formik.touched.cateId && (
                      <div className="error">{formik.errors.cateId}</div>
                    )}
                  </Form.Group>
                </Col>

                {formik.values?.cateId && (
                  <Col md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="required-FIELD">
                        Select Brand
                      </Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                        name="barandId"
                        onChange={(e) => handleChange("barandId", e)}
                        onBlur={formik.handleBlur}
                        value={formik.values?.barandId}
                      >
                        <option>Select Brand</option>
                        {brandList.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.brandName}
                          </option>
                        ))}
                      </Form.Select>
                      {formik.errors.barandId && formik.touched.barandId && (
                        <div className="error">{formik.errors.barandId}</div>
                      )}
                    </Form.Group>
                  </Col>
                )}

                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">
                      Product Name
                    </Form.Label>
                    <Form.Control
                      onChange={formik.handleChange}
                      type="text"
                      name="productName"
                      placeholder={"Enter Product Name"}
                      value={formik.values?.productName}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.productName &&
                      formik.touched.productName && (
                        <div className="error">{formik.errors.productName}</div>
                      )}
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">
                      Product Description
                    </Form.Label>
                    <Form.Control
                      name="description"
                      onChange={formik.handleChange}
                      type="text"
                      as="textarea"
                      rows={1}
                      placeholder={"Enter Description"}
                      value={formik.values?.description}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">
                      Product Images
                    </Form.Label>
                    {/* <Form.Control
                      onChange={(e) => onFileChange(e)}
                      type="file"
                      multiple
                    /> */}

                    <Upload
                      customRequest={dummyRequest}
                      listType="picture-card"
                      fileList={files}
                      onPreview={handlePreview}
                      onChange={handleChangeImage}
                    >
                      {files.length >= 8 ? null : uploadButton}
                    </Upload>
                    <Modal
                      open={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">Price</Form.Label>
                    <Form.Control
                      onChange={(e) => handleChange("price", e)}
                      type="number"
                      placeholder={"Enter Price"}
                      value={formik.values?.price}
                      isInvalid={formik.values?.price < 0}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">SKU No.</Form.Label>
                    <Form.Control
                      type="text"
                      name="mrNo"
                      placeholder={"Enter SKU No."}
                      // onChange={(e) => handleChange("mrNo", e)}
                      value={formik.values?.mrNo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </Form.Group>
                </Col>
                {selectedCategory === "Watches" && (
                  <Col md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="required-FIELD">
                        Case Size
                      </Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                        name="case_size"
                        // onChange={(e) => handleChange("case_size", e)}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.case_size}
                      >
                        <option defaultValue={""}>Select Case Size</option>
                        {sizeRanges.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}
                {selectedCategory == "Watches" && (
                  <Col md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="required-FIELD">
                        Case Material
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="case_material"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.case_material}
                      >
                        <option defaultValue={""}>Select Case Material</option>
                        {materials.filter((el) => el.type === "case").map((option) => (
                          <option key={option?._id} value={option?.name}>
                            {option?.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                )}
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="required-FIELD">Gender</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="gender"
                      // onChange={(e) => handleChange("gender", e)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gender}
                    >
                      <option>Select Gender</option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      {selectedCategory === "sunglasses" && (
                        <option value={"unisex"}>Unisex</option>
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                {selectedCategory === "Jewellery" && (
                  <>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Metals
                        </Form.Label>
                        {/* <Form.Select
                          name="jewellery_metal"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.jewellery_metal}
                        >
                          <option defaultValue={""}>Select Metal Name</option>
                          {metalData?.map((option) => (
                            <option key={option._id} value={option._id}>
                              {option.metalsName}
                            </option>
                          ))}
                        </Form.Select> */}
                        <Select
                          isMulti
                          placeholder='Select Metal'
                          name="jewellery_metal"
                          options={metalData.map((option) => ({
                            value: option._id,
                            label: option.metalsName
                          }))}
                          onChange={(selectedOption) => handleSelectChange('jewellery_metal', selectedOption)}
                          onBlur={formik.handleBlur}
                          value={metalData.find((option) => option._id === formik.values.jewellery_metal)}
                        />
                      </Form.Group>
                    </Col>

                    {formik.values.jewellery_metal && (
                      <Col md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="required-FIELD">
                            Metal Type
                          </Form.Label>
                          <Form.Select
                            name="metal_types"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.metal_types}
                          >
                            <option defaultValue={""}>Select Metal Type</option>
                            {metalData
                              ?.find(
                                (option) =>
                                  option._id === formik.values.jewellery_metal
                              )
                              ?.metalsType?.map((option) => (
                                <>
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                </>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}

                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Jewelry Category
                        </Form.Label>

                        <Form.Select
                          name="jewellery_Type"
                          // onChange={(e) => handleChange("jewellery_Type", e)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.jewellery_Type}
                        >
                          <option defaultValue={""}>
                            Select Jewelry Category
                          </option>
                          {jeweleryData?.map((option) => (
                            <>
                              <option key={option._id} value={option._id}>
                                {option.jewelleryName}
                              </option>
                            </>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {(formik.values.jewellery_Type) && (jeweleryData
                              ?.find(
                                (option) =>
                                  option._id === formik.values.jewellery_Type
                              )
                              ?.jewelleryType?.length > 0)&&(
                      <Col md={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="required-FIELD">
                            Jewelry Sub Category
                          </Form.Label>
                          <Form.Select
                            name="jewellery_types"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jewellery_types}
                          >
                            <option defaultValue={""}>
                              Select Jewelry Sub category
                            </option>
                            {jeweleryData
                              ?.find(
                                (option) =>
                                  option._id === formik.values.jewellery_Type
                              )
                              ?.jewelleryType?.map((option) => (
                                <>
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                </>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    )}

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label className="required-FIELD">Diamonds</Form.Label>
                        <Select
                          isMulti
                          placeholder="Select Diamond"
                          name="jewellery_diamonds"
                          options={diamonds.map((option) => ({
                            value: option.name,
                            label: option.name,
                          }))}
                          onChange={(selectedOption) => handleSelectChange("jewellery_diamonds", selectedOption)}
                          onBlur={formik.handleBlur}
                          value={diamonds.find((option) => option.name === formik.values.jewellery_diamonds)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Stone
                        </Form.Label>
                        {/* <Form.Select
                          aria-label="Default select example"
                          name="jewellery_stone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.jewellery_stone}
                        >
                          <option defaultValue={""}>Select Stone</option>
                          {stoneList.map((option) => (
                            <option key={option?._id} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>{" "} */}
                        <Select
                          isMulti
                          placeholder='Select Stone'
                          name="jewellery_stone"
                          options={stoneList.map(option => ({
                            value: option.name,
                            label: option.name
                          }))}
                          onChange={(selectedOption) => handleSelectChange("jewellery_stone", selectedOption)}
                          onBlur={formik.handleBlur}
                          value={stoneList.find((option) => option.name === formik.values.jewellery_stone)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">Cut</Form.Label>

                        {/* <Form.Select
                          aria-label="Default select example"
                          name="jewellery_cut"
                          // onChange={(e) => handleChange("jewellery_cut", e)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.jewellery_cut}
                        >
                          <option>Select Diamond Shape</option>
                          {diamondShapes.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Select> */}
                        <Select
                          isMulti
                          placeholder='Select Cut'
                          name="jewellery_cut"
                          options={diamondShapes.map(option => ({
                            value: option.name,
                            label: option.name
                          }))}
                          onChange={(selectedOption) => handleSelectChange("jewellery_cut", selectedOption)}
                          onBlur={formik.handleBlur}
                          value={diamondShapes.find((option) => option.name === formik.values.jewellery_cut)}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Color
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="jewellery_color"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.jewellery_color}
                        >
                          <option defaultValue={""}>
                            Select Color
                          </option>
                          {colors.filter((el) => el.type === "jewelry").map((option) => (
                            <option key={option} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </>
                )}

                {selectedCategory === "Watches" && (
                  <>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Movement
                        </Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          name="movement"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.movement}
                        >
                          <option defaultValue={""}>
                            Select Watch Movement
                          </option>
                          {watchMovements.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Strap/ Bracelet Material
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="bracelet_material"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.bracelet_material}
                        >
                          <option defaultValue={""}>
                            Select Material
                          </option>
                          {materials.filter((el) => el.type === "strap/bracelet").map((option) => (
                            <option key={option?._id} value={option?.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Water Resistance
                        </Form.Label>

                        <Form.Select
                          aria-label="Default select example"
                          name="waterResistance"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.waterResistance}
                        >
                          <option defaultValue={""}>
                            Select Water Resistance
                          </option>
                          {watchWaterResistance.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Diamond
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="diamonds"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.diamonds}
                        >
                          <option defaultValue={""}>
                            Select Diamond
                          </option>
                          <option value={"yes"}>
                            Yes
                          </option>
                          <option value={"no"}>
                            No
                          </option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </>
                )}

                {selectedCategory === "sunglasses" && (
                  <>


                    <Col md={6}>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Lens Color
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="lens_color"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lens_color}
                        >
                          <option defaultValue={""}>
                            Select Lens Color
                          </option>
                          {colors.filter((el) => el.type === "lens").map((option) => (
                            <option key={option?._id} value={option?.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>



                    <Col md={6}>
                      <SelectInput
                        label={"Frame Style"}
                        inputName={"frame_style"}
                        onChange={(e) => handleChange("frame_style", e)}
                        arrayName={FRAME_STYLE}
                      />
                    </Col>

                    <Col md={6}>
                      
                        <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Frame Material
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="frame_material"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.frame_material}
                        >
                          <option defaultValue={""}>
                            Select Frame Material
                          </option>
                          {materials.filter((el) => el.type === "frame").map((option) => (
                            <option key={option?._id} value={option?.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    {/* <Col md={6}>
                      <SelectInput
                        label={"Color"}
                        inputName={"colour"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.colour}
                        arrayName={colors.filter((el) => el.type === "frame")}
                      />
                    </Col> */}

                    <Col md={6}>


                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        {/* <Form.Label className="required-FIELD">
                        Frame Color
                        </Form.Label> */}
                        {/* <SelectInput
                          label={"Frame Color"}
                          inputName={"frame_color"}
                          onChange={(e) => handleChange("frame_color", e)}
                          arrayName={FRAME_COLOR}
                        /> */}
                        <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Frame Color
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="frame_color"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.frame_color}
                        >
                          <option defaultValue={""}>
                            Select Frame Color
                          </option>
                          {colors.filter((el) => el.type === "frame").map((option) => (
                            <option key={option?._id} value={option?.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                        {/* <Select
                        isMulti
                        name="colors"
                        options={FRAME_COLOR}
                        onChange={(e) => handleChange("frame_color", e)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      /> */}
                      </Form.Group>


                    </Col>

                    <Col md={6}>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="required-FIELD">
                          Lens Material
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="lens_material"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lens_material}
                        >
                          <option defaultValue={""}>
                            Select Material
                          </option>
                          {materials.filter((el) => el.type === "lens").map((option) => (
                            <option key={option?._id} value={option?.name}>
                              {option.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <SelectInput
                        label={"Fit"}
                        inputName={"fit"}
                        onChange={(e) => handleChange("fit", e)}
                        arrayName={FRAME_FIT}
                      />
                    </Col>
                  </>
                )}
              </Row>

              <Button className="submit_btn" type="submit">
                {id ? "Update Product" : "Add Product"}
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default AddUpdateProduct;
