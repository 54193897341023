import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { httpDelete, httpGet } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import moment from "moment";

function UserContactDetails() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const handleShow = (id) => {
    setShow(true);
    setDeleteId(id);
  };
  const handleClose = () => setShow(false);

  const getContactData = async () => {
    await httpGet(`${BASE_URL}/contact_us/list`)
      .then((res) => {
        if (res?.data?.status) {
          setData(res?.data?.response);
          setIsLoading(false);
          setFilteredData(res?.data?.response);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const deleteContactUsById = async () => {
    try {
      await httpDelete(`${BASE_URL}/contact_us/${deleteId}`).then((res) => {
        if (res?.data?.status) {
          toast.success(res.data.message);
          getContactData();
          setShow(false);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getContactData();
  }, []);

  const colunms = [
    {
      name: (
        <h6>
          <b>Name</b>
        </h6>
      ),
      selector: (row) => row.name,
      sortable: true,
    },

    {
      name: (
        <h6>
          <b>Email</b>
        </h6>
      ),
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Phone Number</b>
        </h6>
      ),
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Message</b>
        </h6>
      ),
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Date</b>
        </h6>
      ),
      selector: (row) => moment(row?.createdAt).format("DD-MM-YYYY"),
    },
    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleShow(row._id)}
          >
            {" "}
            <i className="fas fa-trash" style={{ color: "#f00" }}></i>
          </button>
        </div>
      ),
   
    },
  ];

  useEffect(() => {
    if (data?.length > 0) {
      const result = data.filter((item) => {
        return (
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [search]);

  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          title="User Contact Details"
          columns={colunms}
          data={filteredData}
          pagination
          fixedHeader
          // fixedHeaderScrollHeight="500px"
          progressPending={isLoading}
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={() => deleteContactUsById()}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </DashboardLayout>
  );
}

export default UserContactDetails;
