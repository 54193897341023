import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { FaBars, FaBell } from "react-icons/fa";
import { VscMail } from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../../components/assets/logo.png";

const Header = ({ toggle }) => {
  const navigate = useNavigate();
  let user = JSON.parse(localStorage.getItem("jwt"));
  function LogOut() {
    localStorage.clear();
    toast.success("You have been logged out successfully");
    navigate("/login");
  }
  function gotoProfile() {
    navigate("/profile");
  }
  return (
    <nav className="header navbar-static-top">
      <div className="container-fluid topBarLeft">
        <div className="bars" style={{ cursor: "pointer", color: "white" }}>
          <FaBars onClick={toggle} />
        </div>
        <div>
          <img src={logo} alt="Logo" style={{ width: "140px" }} />
        </div>
        <div>
          <div className="topBarIcons">
            <div className="topBarIconItem">
              <VscMail color="white" />
            </div>
            <div className="topBarIconItem">
              <FaBell color="white" />
            </div>
            <Nav>
              <NavDropdown
                id="nav-dropdown"
                title={user?.name || "Admin"}
                style={{ fontSize: "20px" }}
              >
                <NavDropdown.Item onClick={gotoProfile}>
                  Profile
                </NavDropdown.Item>
                <NavDropdown.Item onClick={LogOut}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
