import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import Loader from "../../components/loader/loader";
import { BASE_URL } from "../../config/endPoint";
import { httpGet, httpPostFormData } from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddBrands = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBrandLogo, setSelectedBrandLogo] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      cateId: "",
      brandName: "",
      file: null,
      brandLogo: null,
    },
    validationSchema: Yup.object({
      cateId: Yup.string().required("Category is required"),
      brandName: Yup.string().required("Brand name is required"),
      brandLogo: Yup.mixed().nullable().required("Brand logo is required"),
      file: Yup.mixed().nullable().required("Brand image is required"),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        setIsLoading(true);
        const formData = new FormData();
        if (id) {
          formData.append("brandId", id);
        }
        formData.append("cateId", values.cateId);
        formData.append("brandName", values.brandName);
        // values.brandType.forEach((brandType, index) => {
        //   formData.append(`brandType[${index}]`, brandType);
        // });
        formData.append("brandLogo", selectedBrandLogo);
        formData.append("file", selectedFile);

        let url = id ? "/brand/update" : "/brand/create";

        const result = await httpPostFormData(`${BASE_URL}${url}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        setIsLoading(false);

        if (result.data.status) {
          toast.success(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/brands");
        } else {
          toast.error(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  useEffect(() => {
    getCategory();
    if (id) {
      getBrandById();
    }
  }, [id]);

  const getCategory = async () => {
    try {
      const res = await httpGet(`${BASE_URL}/category/list`);
      if (res?.data?.status) {
        setCategoryList(res.data.response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBrandById = async () => {
    setIsLoading(true);
    await httpGet(`${BASE_URL}/brand/${id}`)
      .then((res) => {
        console.log("res?.data?.response", res?.data?.response);
        let data = res?.data?.response;
        if (data) {
          setIsLoading(false);
          formik.setFieldValue("cateId", data?.cateId);
          formik.setFieldValue("brandName", data?.brandName || "");
          formik.setFieldValue("file",data?.image || null );
          formik.setFieldValue("brandLogo", data?.brandLogo || null);
          console.log("data", data);
          setSelectedBrandLogo(data?.brandLogo || "");
          setSelectedFile(data?.image || "");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleFilePreview = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file;
  };

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/brands">
                <AiOutlineLeft fa-lg />
                Add Brand
              </Link>
            </div>
            <hr />
            {isLoading && <Loader />}

            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="selectCategory">
                <Form.Label className="required-FIELD">
                  Select Category
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="cateId"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cateId}
                >
                  <option>Select Category</option>
                  {categoryList.map((option) => (
                    <option key={option._id} value={option._id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
                {formik.touched.cateId && formik.errors.cateId && (
                  <div className="error">{formik.errors.cateId}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="brandName">
                <Form.Label className="required-FIELD">Brand</Form.Label>
                <Form.Control
                  name="brandName"
                  type="text"
                  placeholder={"Enter Add Brand"}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.brandName}
                />
                {formik.touched.brandName && formik.errors.brandName && (
                  <div className="error">{formik.errors.brandName}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="brandLogo">
                <Form.Label className="required-FIELD">Brand Logo</Form.Label>
                <Form.Control
                  name="brandLogo"
                  type="file"
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setSelectedBrandLogo(event.target.files[0]);
                  }}
                  value={undefined}
                />
                {formik.touched.brandLogo && formik.errors.brandLogo && (
                  <div className="error">{formik.errors.brandLogo}</div>
                )}
                {selectedBrandLogo && (
                  <img
                    src={handleFilePreview(selectedBrandLogo)}
                    alt="Brand Logo Preview"
                    style={{
                      width: "200px",
                      height: "auto",
                      marginTop: "10px",
                    }}
                  />
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="brandImage">
                <Form.Label className="required-FIELD">Brand Image</Form.Label>
                <Form.Control
                  type="file"
                  name="file"
                  accept="image/*"
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                    setSelectedFile(event.target.files[0]);
                  }}
                  value={undefined} 
                />
                {formik.touched.file && formik.errors.file && (
                  <div className="error">{formik.errors.file}</div>
                )}
                {selectedFile && (
                  <img
                    src={handleFilePreview(selectedFile)}
                    alt="Brand Preview"
                    style={{
                      width: "200px",
                      height: "auto",
                      marginTop: "10px",
                    }}
                  />
                )}
              </Form.Group>

              <Button className="submit_btn" type="submit">
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
};

export default AddBrands;
