import axios from 'axios';

const token = JSON.parse(localStorage.getItem('token'));

export const getCommonHeaders = () => {
  const headers = token
  ? {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    }
  : {
   Accept: "application/json" };
return headers;
};



export const httpDelete = async (url) => {
  return axios
    .delete(url, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.res;
    });
};

export const httpPost = async (url, body) => {
  return axios
    .post(url, body, {  
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpPut = async (url, body) => {
  return axios
    .put(url, body, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.res;
    });
};

export const httpPostFormData = async (url, body) => {
  let commonHeaders = getCommonHeaders();
  delete commonHeaders.Accept;

  return axios
    .post(url, body, {  
      headers: commonHeaders,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpPutFormData = async (url, body) => {
  let commonHeaders = getCommonHeaders();
  delete commonHeaders.Accept;

  return axios
    .put(url, body, {  
      headers: commonHeaders,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export const httpGet = async (url) => {
  const token = JSON.parse(localStorage.getItem("token"));

  return axios
    .get(url, {
      headers: {
        ...getCommonHeaders(),
        Authorization: `Bearer ${token}`, 
      },
    })
    .then((res) => {
      console.log("url : ", url);
      console.log("res: ", res);
      return res;
    })
    .catch((err) => {
      console.log('catch : ', err?.response?.status);
      if (err?.response?.status === 401) {
        localStorage.clear(); // Clear localStorage if token is invalid or expired
        window.location.href = '/login';
      }
      return err.response;
    });
};
