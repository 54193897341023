import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import { BASE_URL } from "../../config/endPoint";
import { httpDelete, httpGet } from "../../utils/api";

function StoneType() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const getData = async () => {
    await httpGet(`${BASE_URL}/stone/list`)
      .then((res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          setData(res.data.response);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const deleteStone = async () => {
    await httpDelete(`${BASE_URL}/stone/${deleteId}`)
      .then((res) => {
        if (res?.data?.status) {
          setDeleteId("");
          getData();
          toast.success(res?.data?.message);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const colunms = [
    {
      name: (
        <h6>
          <b>Stone Name</b>
        </h6>
      ),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            onClick={() => navigate(`/editStoneType/${row._id} `)}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            {" "}
            <i className="fa-solid fa-pen fa-lg" style={{ color: "blue" }}></i>
          </button>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleShow(row._id)}
          >
            {" "}
            <i className="fas fa-trash" style={{ color: "#f00" }}></i>
          </button>
        </div>
      ),
    },
  ];

  const actions = (
    <Button
      className="add-btn"
      type="button"
      onClick={() => navigate("/addStoneType")}
    >
      Add Stone
    </Button>
  );

  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          title="Stone Type"
          columns={colunms}
          data={data}
          pagination
          fixedHeader
          progressPending={isLoading}
          // fixedHeaderScrollHeight="500px"
          selectableRowsHighlight
          actions={actions}
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Stone Type</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteStone}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default StoneType;
