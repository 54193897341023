import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import { BASE_URL } from "../../config/endPoint";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpDelete, httpGet, httpPost, httpPut } from "../../utils/api";

function DiamondTable() {
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleShowDeleteModal = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };
  const handleCloseEditModal = () => setShowEditModal(false);
  const handleShowEditModal = (item) => {
    setEditItem(item);
    formikEdit.setFieldValue('name',  item?.name);
    setShowEditModal(true);
  };
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);

  const formikAdd = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleAddDiamond(values);
    },
  });

  const formikEdit = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleEditDiamond(editItem._id, values);
    },
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await httpGet(`${BASE_URL}/diamonds`);
      if (response?.data?.status) {
        setIsLoading(false);
        setData(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const deleteItem = async () => {
    try {
      const response = await httpDelete(`${BASE_URL}/diamond/${deleteId}`);
      if (response?.data?.status) {
        setDeleteId("");
        getData();
        toast.success(response?.data?.message);
        setShowDeleteModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddDiamond = async (values) => {
    try {
      setIsLoading(true);
      const response = await httpPost(`${BASE_URL}/diamond`, values);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        getData();
        handleCloseAddModal();
      } else {
        toast.error(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error adding diamond:", error);
      setIsLoading(false);
      toast.error("Failed to add diamond");
    }
  };

  const handleEditDiamond = async (id, values) => {
    try {
      setIsLoading(true);
      const response = await httpPut(`${BASE_URL}/diamond/${id}`, values);
      if (response?.data?.status) {
        toast.success(response?.data?.message);
        getData();
        handleCloseEditModal();
      } else {
        toast.error(response?.data?.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error editing diamond:", error);
      setIsLoading(false);
      toast.error("Failed to edit diamond");
    }
  };

  const colunms = [
    {
      name: (
        <h6>
          <b>Diamond</b>
        </h6>
      ),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            onClick={() => handleShowEditModal(row)}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            {" "}
            <i className="fa-solid fa-pen fa-lg" style={{ color: "blue" }}></i>
          </button>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleShowDeleteModal(row._id)}
          >
            {" "}
            <i className="fas fa-trash" style={{ color: "#f00" }}></i>
          </button>
        </div>
      ),
    },
  ];

  const actions = (
    <Button className="add-btn" type="button" onClick={handleShowAddModal}>
      Add Diamond
    </Button>
  );

  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          title="Diamond"
          columns={colunms}
          data={data}
          pagination
          fixedHeader
          progressPending={isLoading}
          // fixedHeaderScrollHeight="500px"
          selectableRowsHighlight
          actions={actions}
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />

        <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Diamond</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDeleteModal}>
              No
            </Button>
            <Button variant="primary" onClick={deleteItem}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEditModal} onHide={handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Diamond</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formikEdit.handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter diamond name"
                  value={formikEdit.values.name}
                  onChange={formikEdit.handleChange}
                  onBlur={formikEdit.handleBlur}
                  name="name"
                />
                {formikEdit.touched.name && formikEdit.errors.name ? (
                  <div className="error">{formikEdit.errors.name}</div>
                ) : null}
              </Form.Group>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showAddModal} onHide={handleCloseAddModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Diamond</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={formikAdd.handleSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter diamond name"
                  value={formikAdd.values.name}
                  onChange={formikAdd.handleChange}
                  onBlur={formikAdd.handleBlur}
                  name="name"
                />
                {formikAdd.touched.name && formikAdd.errors.name ? (
                  <div className="error">{formikAdd.errors.name}</div>
                ) : null}
              </Form.Group>
              <Button variant="primary" type="submit">
                Add
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default DiamondTable;
