import React, { useEffect, useState } from "react";
import "./login.css";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/esm/Container";
import logo from "../../components/assets/logo.png";
import { toast } from "react-toastify";
import { authenticate } from "../../helper/ApiCall.js";
import { useCookies } from "react-cookie";
import { BASE_URL } from "../../config/endPoint.js";
import { httpPost } from "../../utils/api.js";
import { InputGroup } from "react-bootstrap";

function Login() {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    success: "",
  });
  const [showPass, setShowPass] = useState(false);
  const showHidePassword = () => {
    setShowPass((prev) => !prev);
  };
  useEffect(() => {
    console.log('jwt : ', localStorage.getItem("jwt"));
    if (localStorage.getItem("jwt")) {
      toast.warning("You are logged In", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/dashboard");
    }
  }, []);

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const [cookie, setCookie, removeCookie] = useCookies([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      return toast.error("Please Fill out all the Fields");
    }
    if (checkbox === true) {
      setCookie("email", email);
      setCookie("password", password);
    }
  };

  const { email, password } = values;

  // const Login = async () => {
  //   let item = { email, password };
  //   let result = await fetch(`${BASE_URL}/auth/login`, {
  //     method: "POST",
  //     body: JSON.stringify(item),
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //   });
  //   result = await result.json();
  //   if (result) {
  //     console.log("result", result);
  //     if (!result.status) {
  //       toast.error(result.message, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //       setValues({ ...values, error: result.data.message, success: false });
  //     } else if (result.status) {
  //       authenticate(result, () => {
  //         setValues({
  //           ...values,
  //           success: true,
  //           isMember: false,
  //         });
  //         navigate("/welcome");
  //         toast.success("Logged In Successfully");
  //       });
  //     }
  //   }

  //   console.log(result);
  // }

  const Login = async () => {
    let data = { email, password };
    try {
      await httpPost(`${BASE_URL}/auth/admin/login`, data).then((response) => {
        if (response?.data?.status) {
          localStorage.setItem("jwt", JSON.stringify(response?.data?.response?.user));
          localStorage.setItem(
            "token",
            JSON.stringify(response?.data?.response?.token)
          );
          navigate("/dashboard");
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [checkbox, setCheckbox] = useState(false);
  const handleCheckChange = (e) => {
    setCheckbox(e.target.checked);
  };

  return (
    <Container id="container" className="d-grid">
      <Form id="login-form" className=" w-100 mt-0" onSubmit={handleSubmit}>
        <div id="header">
          <img className="logo" src={logo} alt="" />
        </div>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter email"
            onChange={handleChange}
            name="email"
            value={cookie.email ? (values.email = cookie.email) : values.email}
          />
          <p style={{ color: "red", fontWeight: "bold" }}>{formErrors.email}</p>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <InputGroup className="mb-3">
          <Form.Control
            onChange={handleChange}
            // onChange={handle}
            type={showPass ? "text" : "password"}
            placeholder="Password"
            name="password"
            value={
              cookie.password
                ? (values.password = cookie.password)
                : values.password
            }
            // value={values.password}
          />
          <InputGroup.Text>
              <i onClick={showHidePassword} class={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
          </InputGroup.Text>
          </InputGroup>
          <p style={{ color: "red", fontWeight: "bold" }}>
            {formErrors.password}
          </p>
        </Form.Group>

        <button id="btn" onClick={Login}>
          Login
        </button>
        <br />
        <br />
      </Form>
    </Container>
  );
}

export default Login;
