import React, {  useState } from "react";
import "../components/auth/login.css";
import { useNavigate } from "react-router-dom";
import {Form,Container} from "react-bootstrap";
import { toast } from "react-toastify";
import { BASE_URL } from "../config/endPoint";

function ChangePassword() {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('jwt'));
  const [values, setValues] = useState({
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(`${name}-${value}`);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const { oldPassword, newPassword, confirmPassword } = values;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if ( !oldPassword || !newPassword || !confirmPassword) {
      console.log("Please Fill out all the Fields");
      return toast.error("Please Fill out all the Fields");
    }
    if(newPassword !== confirmPassword){
      return toast.error("New Password and Confirm password does not match!");
    }
    await setPwd();
  };

  const setPwd = async () => {
    let item = { oldPassword, newPassword };
    await fetch(`${BASE_URL}/auth/admin/change_password`, {
      method: "POST",
      body: JSON.stringify(item), 
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${user.token}`
      },
    }).then((result) => {
      result.json().then((res) => {
        if(res.status){
          toast.success(res?.message, {
            position: toast.POSITION.TOP_CENTER,
          }); 
          // localStorage.clear();
          setTimeout(() => {
            navigate("/login");
          }, 2000);
          return;
        }
        toast.error(res?.message)
      });
    });
  }

  return (
    <Container id="container" className="d-grid">
      <Form id="login-form" className=" w-100 mt-0" onSubmit={handleSubmit}>
        {/* <div id="header">
          <img className="logo" src={logo} alt="" />
        </div> */}
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Old Password</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="password"
            placeholder="Enter Old Password"
            name="oldPassword"
            value={oldPassword}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>New Password</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="password"
            placeholder="Enter New Password"
            name="newPassword"
            value={newPassword}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            onChange={handleChange}
            type="password"
            placeholder="Confirm Password"
            name="confirmPassword"
            value={confirmPassword}
          />
        </Form.Group>
        <button id="btn" type="submit">
          Save
        </button>
      </Form>
    </Container>
  );
}

export default ChangePassword;