import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { httpGet, httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/layouts/dashboardLayout";

function HomePageStory() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  const getData = async () => {
    await httpGet(`${BASE_URL}/home/story/list`)
      .then((res) => {
        if (res?.data?.status) {
          setData(res?.data?.response);
          setisLoading(false);
          setFilteredData(res?.data?.response);
        }
      })
      .catch((error) => {
        console.error(error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const colunms = [
    {
      name: (
        <h6>
          <b>Title</b>
        </h6>
      ),
      selector: (row) => row.title.slice(0, 50),
      sortable: true,
    },

    {
      name: (
        <h6>
          <b>Description</b>
        </h6>
      ),
      selector: (row) => row.description.slice(0, 100),
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Images</b>
        </h6>
      ),
      cell: (row) => (
        <div>
          {row.images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              style={{ width: "50px", marginRight: "10px", height: "auto" }}
            />
          ))}
        </div>
      ),
    },

    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            onClick={() => navigate(`/updateStory/${row._id} `)}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            {" "}
            <i className="fa-solid fa-pen fa-lg" style={{ color: "blue" }}></i>
          </button>
        </div>
      ),
     
    },
  ];

  useEffect(() => {
    if (data?.length > 0) {
      const result = data.filter((item) => {
        return (
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.email.toLowerCase().includes(search.toLowerCase()) ||
          item.phoneNumber.toLowerCase().includes(search.toLowerCase())
        );
      });
      setFilteredData(result);
    }
  }, [search]);
  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          title="Add Story"
          columns={colunms}
          data={filteredData}
          pagination
          fixedHeader
          selectableRowsHighlight
          highlightOnHover
          subHeader
          progressPending={isLoading}
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />
      </div>
    </DashboardLayout>
  );
}

export default HomePageStory;
