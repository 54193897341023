import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { useNavigate } from "react-router-dom";
import { httpDelete, httpGet } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import DashboardLayout from "../../components/layouts/dashboardLayout";

function OffersList() {
  const [search, setSearch] = useState("");
  const [offersList, setOffersList] = useState([]);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setOfferId(id);
    setShow(true);
  };

  const getOffers = async () => {
    setIsLoading(true);
    await httpGet(`${BASE_URL}/offer`)
      .then((res) => {
        console.log("res", res?.data?.data);
        if (res?.data?.status) {
          setOffersList(res?.data?.data);
          setIsLoading(false);
        }
        console.log("offerslist", offersList);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getOffers();
  }, []);

  const columns = [
    {
      name: (
        <h6>
          <b>Category Name</b>
        </h6>
      ),
      selector: (row) => row?.category?.name,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Offer Title</b>
        </h6>
      ),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Offer Description</b>
        </h6>
      ),
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: (
        <h6>
          <b>Image</b>
        </h6>
      ),
      cell: (row) => (
        <div>
          <img
            src={row.image}
            alt=""
            style={{ width: "50px", marginRight: "10px", height: "auto" }}
          />
        </div>
      ),
    },

    {
      name: (
        <h6>
          <b>Action</b>
        </h6>
      ),
      selector: (row) => row.action,
      right: true,
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "50px",
          }}
        >
          <button
            onClick={() => navigate(`/edit-offer/${row._id} `)}
            style={{ border: "none", backgroundColor: "transparent" }}
          >
            {" "}
            <i className="fa-solid fa-pen fa-lg" style={{ color: "blue" }}></i>
          </button>
          <button
            style={{ border: "none", backgroundColor: "transparent" }}
            type="button"
            onClick={() => handleShow(row._id)}
          >
            {" "}
            <i className="fas fa-trash" style={{ color: "#f00" }}></i>
          </button>
        </div>
      ),
      
    },
  ];

  const customStyles = {
    tableWrapper: {
      style: {
        display: "table",
        paddingLeft: "16px",
      },
    },
    rows: {
      style: {
        minHeight: "72px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const deleteBrand = async () => {
    await httpDelete(`${BASE_URL}/offer/${offerId}`)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          setOfferId("");
          getOffers();
          toast.success(res?.data?.message);
          setShow(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filteredItems = offersList.filter(
    (item) =>
      item.brandName &&
      item.brandName.toLowerCase().includes(search.toLowerCase())
  );

  const actions = (
    <Button
      className="add-btn btn-dark"
      type="button"
      onClick={() => navigate("/add-offer")}
    >
      Add Offer
    </Button>
  );

  return (
    <DashboardLayout>
      <div className="table-outer ">
        <DataTable
          title="Offers List"
          columns={columns}
          data={offersList}
          pagination
          fixedHeader
          actions={actions}
          customStyles={customStyles}
          progressPending={isLoading}
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteBrand}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </DashboardLayout>
  );
}

export default OffersList;
