import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { httpGet, httpPost } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import Loader from "../../components/loader/loader";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DeleteFilled } from "@ant-design/icons";

function AddStory() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [data, setData] = useState({
    title: "",
    subTitle: "",
    description: "",
    _id: id,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  // useEffect(() => {
  //   setFiles(imagePreview.map((file) => file instanceof File ? file : file));
  // }, [imagePreview]);

  const formik = useFormik({
    initialValues: {
      title: "",
      subTitle: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      subTitle: Yup.string().required("Sub Title is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        let formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("subTitle", values.subTitle);

        for (let i = 0; i < files.length; i++) {
          if (files[i] !== null) {
            formData.append("files", files[i]);
          }
        }


        formData.append("_id", data._id);

        const result = await httpPost(
          `${BASE_URL}/home/story/update`,
          formData
        );

        if (result.data.status) {
          toast.success(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          navigate("/homePageStory");
          return;
        } else {
          toast.error(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
  });

  const handleChange = (e) => {
    let { name, value } = e.target;
    formik.setFieldValue(name, value);
  };

  const onFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    setImagePreview(selectedFiles.map((file) => handleFilePreview(file)));
    formik.setFieldTouched("image");
  };

  const handleFilePreview = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file;
  };

  const getData = async () => {
    try {
      const response = await httpGet(`${BASE_URL}/home/story/${id}`);
      if (response?.data?.status) {
        setData(response?.data?.response);
        setImagePreview(response?.data?.response?.images);
        setFiles(response?.data?.response?.images);
        formik.setValues(response?.data?.response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeImage = (index) => {
    const updatedFiles = [...files];
    const updatedPreview = [...imagePreview];

    updatedFiles.splice(index, 1, null);
    updatedPreview.splice(index, 1);

    setFiles(updatedFiles);
    setImagePreview(updatedPreview);
  };

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/addBrands">
                <AiOutlineLeft fa-lg />
                Update Story
              </Link>
            </div>
            <hr />
            {isLoading && <Loader />}
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label className="required-FIELD">Title</Form.Label>
                <Form.Control
                  value={formik.values.title}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  name="title"
                  type="text"
                  placeholder="Enter Title"
                />
                {formik.touched.title && formik.errors.title && (
                  <div className="error">{formik.errors.title}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="subtitle">
                <Form.Label className="required-FIELD">Sub Title</Form.Label>
                <Form.Control
                  value={formik.values.subTitle}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  name="subTitle"
                  type="text"
                  placeholder="Enter Sub Title"
                />
                {formik.touched.subTitle && formik.errors.subTitle && (
                  <div className="error">{formik.errors.subTitle}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="description">
                <Form.Label className="required-FIELD">Description</Form.Label>
                <Form.Control
                  value={formik.values.description}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  name="description"
                  as="textarea"
                  rows={4}
                  placeholder="Enter Description"
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="error">{formik.errors.description}</div>
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="image">
                <Form.Label className="required-FIELD">Image</Form.Label>
                <Form.Control
                  type="file"
                  name="image"
                  onChange={(e) => {
                    onFileChange(e);
                    formik.setFieldTouched("image");
                  }}
                  multiple
                  value={undefined}
                />
                {formik.touched.image && !files.length && (
                  <div className="error">Please select an image</div>
                )}
              </Form.Group>
              {/* {data.images && data.images.length > 0 && (
                <div>
                  <strong>Selected Images:</strong>
                  {data.images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      style={{ width: "100px", height: "auto", marginRight: "10px" }}
                    />
                  ))}
                </div>
              )} */}

              {imagePreview.length > 0 && (
                <div className="d-flex flex-column">
                  {imagePreview.map((preview, index) => (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          key={index}
                          src={preview}
                          alt={`Selected Image ${index + 1}`}
                          style={{
                            width: "100px",
                            height: "auto",
                          }}
                        />

                        <Button
                          variant="danger"
                          size="sm"
                          className="ms-2"
                          onClick={() => removeImage(index)}
                        >
                          <DeleteFilled />
                        </Button>
                      </div>
                    </>
                  ))}
                </div>
              )}

              <Button className="submit_btn" type="submit">
                Save
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
}

export default AddStory;
