import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AiOutlineLeft } from "react-icons/ai";

import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useFileSelection from "../../components/hooks/useFileSelection";
import DashboardLayout from "../../components/layouts/dashboardLayout";
import Loader from "../../components/loader/loader";
import { BASE_URL } from "../../config/endPoint";
import { httpGet, httpPost } from "../../utils/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DeleteFilled } from "@ant-design/icons";

function AddHomeBanner() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [phoneFiles, setPhoneFiles] = useState([]);
  const [data, setData] = useState({
    _id: id,
  });

  useEffect(() => {
    fetchSingleBanner();
  }, []);

  const fetchSingleBanner = async () => {
    try {
      const result = await httpGet(`${BASE_URL}/home/banner/${id}`);
      if (result?.data?.status) {
        setData(result.data.response);
        // formik.setFieldValue("files", (result?.data?.response?.images || []))
        // formik.setFieldValue("phoneFiles", (result?.data?.response?.phonefile || []))
        setFiles(result?.data?.response?.images || []);
        setPhoneFiles(result?.data?.response?.phonefile || []);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onFileChange = (e) => {
    const fileArray = Array.from(e.target.files);
    setFiles(fileArray);
  };

  const onPhoneFileChange = (e) => {
    const phoneFileArray = Array.from(e.target.files);
    setPhoneFiles(phoneFileArray);
  };

  const removeFile = (index, fileType) => {
    if (fileType === "files") {
      setFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles.splice(index, 1);
        return updatedFiles;
      });
    } else if (fileType === "phoneFiles") {
      setPhoneFiles((prevPhoneFiles) => {
        const updatedPhoneFiles = [...prevPhoneFiles];
        updatedPhoneFiles.splice(index, 1);
        return updatedPhoneFiles;
      });
    }
  };

  const handleFilePreview = (file) => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file;
  };

  const validationSchema = Yup.object().shape({
    // files: Yup.array().min(1, "Please add at least one banner image"),
    // phoneFiles: Yup.array().min(1, "Please add at least one phone banner image"),
  });

  const formik = useFormik({
    initialValues: {
      files: files,
      phoneFiles: phoneFiles,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        let formData = new FormData();

        files.forEach((file) => {
          formData.append("files", file);
        });

        phoneFiles.forEach((phoneFile) => {
          formData.append("phone_files", phoneFile);
        });

        formData.append("_id", data._id);

        const result = await httpPost(`${BASE_URL}/home/banner/update`, formData);

        if (result.data.status) {
          toast.success(result.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          navigate("/homePageBanner");
        }
      } catch (error) {
        setIsLoading(false);
      }
    },
  });

  return (
    <DashboardLayout>
      <div className="titles">
        <Container>
          <div className="admin-main">
            <div className="titles_backBtn">
              <Link to="/addBrands">
                <AiOutlineLeft fa-lg />
                Update Banner Images
              </Link>
            </div>
            <hr />
            {isLoading && <Loader />}
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-3" controlId="image">
                <Form.Label className="required-FIELD">Add Banners</Form.Label>
                <Form.Control
                  type="file"
                  name="files"
                  onChange={(e) => {
                    formik.setFieldValue("files", Array.from(e.target.files));
                    onFileChange(e);
                  }}
                  multiple
                  value={undefined}
                />
                {formik.errors.files && formik.touched.files && (
                  <div className="error">{formik.errors.files}</div>
                )}
                <div className="file-preview my-2">
                  {files.map((file, index) => (
                    <div key={index} className="preview-item d-flex align-items-center mb-3">
                      <img
                        style={{ width: "100px", height: "auto" }}
                        src={handleFilePreview(file)}
                        alt={`Preview ${index}`}
                      />
                      <button className="btn btn-danger ms-2" type="button" onClick={() => removeFile(index, "files")}>
                        <DeleteFilled/>
                      </button>
                    </div>
                  ))}
                </div>
              </Form.Group>

              <Form.Group className="mb-3" controlId="image">
                <Form.Label className="required-FIELD">
                  Add Phone Banners
                </Form.Label>
                <Form.Control
                  type="file"
                  name="phoneFiles"
                  onChange={(e) => {
                    formik.setFieldValue("phoneFiles", Array.from(e.target.files));
                    onPhoneFileChange(e);
                  }}
                  multiple
                  value={undefined}
                />
                {formik.errors.phoneFiles && formik.touched.phoneFiles && (
                  <div className="error">{formik.errors.phoneFiles}</div>
                )}
                <div className="file-preview my-2">
                  {phoneFiles.map((file, index) => (
                    <div key={index} className="preview-item  d-flex align-items-center mb-3">
                      <img
                        style={{ width: "100px", height: "auto" }}
                        src={handleFilePreview(file)}
                        alt={`Preview ${index}`}
                      />
                      <button className="btn btn-danger ms-2" type="button" onClick={() => removeFile(index, "phoneFiles")}>
                      <DeleteFilled/>
                      </button>
                    </div>
                  ))}
                </div>
              </Form.Group>

              <Button className="submit_btn" type="submit">
                Save
              </Button>
            </Form>
          </div>
        </Container>
      </div>
    </DashboardLayout>
  );
}

export default AddHomeBanner;
