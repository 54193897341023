import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../config/endPoint";
import { httpGet } from "../../utils/api";
import DashboardLayout from "../layouts/dashboardLayout";
const Dashboard = () => {
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productData, setProductData] = useState([]);
  const [contactData, setContactData] = useState([]);

  useEffect(() => {
    console.log("Jwt after login :", localStorage.getItem("jwt"));
    if (localStorage.getItem("jwt")) {
      getBrands();
      getCategory();
      getProducts();
      getContactData();
    } else {
      navigate("/login");
    }
  }, []);

  const getBrands = async () => {
    await httpGet(`${BASE_URL}/brand/list`)
      .then((res) => {
        console.log("Res : ", res);
        if (res?.data?.status) {
          setBrandList(res.data.response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getCategory = async () => {
    try {
      await httpGet(`${BASE_URL}/category/list`).then((res) => {
        if (res?.data?.status) {
          console.log("res", res);
          setCategoryList(res.data.response);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getProducts = async () => {
    await httpGet(`${BASE_URL}/product/list`)
      .then((res) => {
        if (res?.data?.status) {
          setProductData(res.data.response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getContactData = async () => {
    await httpGet(`${BASE_URL}/contact_us/list`)
      .then((res) => {
        if (res?.data?.status) {
          setContactData(res?.data?.response);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <DashboardLayout>
      <div className="p-2">
        <Row className="gy-4">
          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Title>Total Category</Card.Title>
                <Card.Text>{categoryList.length}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Title>Total Brands</Card.Title>
                <Card.Text>{brandList.length}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Title>Total Products</Card.Title>
                <Card.Text>{productData.length}</Card.Text>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Title>Total Users Contacts</Card.Title>
                <Card.Text>{contactData.length}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
