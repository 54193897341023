import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import './button.css'
const TagButton = ({ label, onDelete }) => {
  return (
    <div className="tag-button">
      <span>{label}</span>
      <IconButton size="small" onClick={onDelete}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default TagButton;
