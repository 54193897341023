import React from 'react'
import { Form } from 'react-bootstrap'

function SelectInput({ label, inputName, onChange, arrayName, onBlur, value }) {
    return (
        <>
            <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
            >
                <Form.Label className="required-FIELD">{label}</Form.Label>
                <Form.Select aria-label="Default select example" name={inputName} onChange={onChange} onBlur={onBlur} value={value}>
                <option defaultValue={''}>Select {label}</option>
                    {arrayName.map((option) => (
                        <option key={option.name} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </>
    )
}

export default SelectInput
