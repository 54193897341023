import { FaHome } from "react-icons/fa";
import { FcDepartment, FcMoneyTransfer } from "react-icons/fc";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FaHome />,
  },
  {
    path: "/category",
    name: "Category",
    icon: <FcDepartment />,
  },
  {
    path: "/brands",
    name: "Brands",
    icon: <FcMoneyTransfer />,
  },
  {
    path: "/products",
    name: "Products",
    icon: <i className="fa-solid fa-cart-shopping"></i>,
  },
  {
    path: "/userContactList",
    name: "User Contacts",
    icon: <i className="fas fa-users"></i>,
  },
  {
    path: "/jeweleryType",
    name: "Jewelry Type",
    icon: <i className="fa-solid fa-ring"></i>,
  },
  {
    path: "/metalType",
    name: "Metal Type",
    icon: <i className="fas fa-coins"></i>,
  },
  {
    path: "/stones",
    name: "Stone Type",
    icon: <i className="fas fa-coins"></i>,
  },
  {
    path: "/diamonds",
    name: "Diamond",
    icon: <i className="fas fa-coins"></i>,
  },
  {
    path: "/color",
    name: "Color",
    icon: <i className="fas fa-coins"></i>,
  },
  {
    path: "/material",
    name: "Material",
    icon: <i className="fas fa-coins"></i>,
  },
  {
    path: "/product-inquiry",
    name: "Product Inquiry",
    icon: <i className="fa-solid fa-phone"></i>,
  },
  {
    path: "/homePageStory",
    name: "Home Page Story",
    icon: <i className="fa-solid fa-store"></i>,
  },
  {
    path: "/homePageBanner",
    name: "Home Banner",
    icon: <i className="fa-solid fa-store"></i>,
  },
  {
    path: "/offers",
    name: "Offers",
    icon: <i className="fa-solid fa-store"></i>,
  },
  {
    path: "/grabbed-offers",
    name: "Grabbed Offers List",
    icon: <i className="fa-solid fa-store"></i>,
  },
];

const SideBar = ({ isOpen, setIsOpen }) => {
  return (
    <section className="routes" style={{ marginLeft: "10px" }}>
      {routes.map((route, index) => {
        if (route.subRoutes) {
          return (
            <SidebarMenu
              setIsOpen={setIsOpen}
              route={route}
              // showAnimation={showAnimation}
              isOpen={isOpen}
            />
          );
        }

        return (
          <NavLink
            to={route.path}
            key={index}
            className="link"
            activeclassname="active"
          >
            <div className="icon">{route.icon}</div>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="link_text"
                >
                  {route.name}
                </motion.div>
              )}
            </AnimatePresence>
          </NavLink>
        );
      })}
    </section>
  );
};

export default SideBar;
