import axios from "axios";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";

import { Tooltip, Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { httpGet } from "../../utils/api";
import { BASE_URL } from "../../config/endPoint";
import DashboardLayout from "../../components/layouts/dashboardLayout";

const Category = () => {
  const [search, setSearch] = useState("");
  const [countries, setCountries] = useState([]);
  const [filtercountries, setFiltercountries] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    localStorage.removeItem("AppId");
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    setIsLoading(true);
    try {
      await httpGet(`${BASE_URL}/category/list`).then((res) => {
        if (res?.data?.status) {
          setIsLoading(false);
          setCategoryList(res.data.response);
        }
      });
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const colunms = [
    {
      name: (
        <h6>
          <b>Category Name</b>
        </h6>
      ),
      selector: (row) => row.name,
      sortable: true,
    },

    // {
    //   name: (
    //     <h6>
    //       <b>Action</b>
    //     </h6>
    //   ),
    //   cell: (row) => (
    //     <div
    //       style={{
    //         display: "flex",
    //         justifyContent: "space-between",
    //         width: "110px",
    //       }}
    //     >
    //       <button onClick={() => navigate(`/editCategory/${row._id} `)} style={{ border: "none", backgroundColor: "transparent" }}>
    //         {" "}
    //         <i className="fa-solid fa-pen fa-lg" style={{ color: "blue", backgroundColor: "white" }}></i>
    //       </button>

    //     </div>
    //   ),
    // },
  ];
  const filteredItems = categoryList.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(search.toLowerCase())
  );
  return (
    <DashboardLayout>
      <div className="table-outer">
        <DataTable
          className="table"
          title="Category"
          columns={colunms}
          data={categoryList}
          pagination
          fixedHeader
          progressPending={isLoading}
          // fixedHeaderScrollHeight="500px"
          selectableRowsHighlight
          highlightOnHover
          subHeader
          subHeaderComponent={
            <>
              <input
                type="text"
                placeholder="Search here"
                className="  form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </>
          }
        />
        <Tooltip style={{ float: "right" }}>
          <Fab color="#f6e58d" aria-label="add">
            <Link to="/addCategory">
              <Add fontSize="large" />
            </Link>
          </Fab>
        </Tooltip>
        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Important message</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
            <Button variant="primary" onClick={deleteUser}>
              Yes
            </Button>
          </Modal.Footer>
      </Modal> */}
      </div>
    </DashboardLayout>
  );
};

export default Category;
